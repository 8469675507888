import React from "react";
// Assets
import Logo from "../../../assets/img/logo.svg";

const AppLoader: React.FC = () => {
  return (
    <div className="loader">
      <img src={Logo} alt="Loader" />
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default AppLoader;
