import React from "react";
import { Link } from "react-router-dom";

const NOT_FOUND = "Not Found";

interface Props {
  message?: string;
}

const NotFound: React.FC<Props> = ({ message }) => {
  return (
    <div className="text-center mt-5">
      <p className="text-muted mb-3">Oops.. {message || NOT_FOUND}</p>
      <Link to="/" className="go-back">
        Back to Dashboard
      </Link>
    </div>
  );
};

export default NotFound;
