import React from "react";

interface Props {
  title: string;
}

const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <div className="page-title d-inline-block">
      <h2>{title}</h2>
    </div>
  );
};

export default PageTitle;
