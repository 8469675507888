import React, { ChangeEvent, useState, useEffect } from "react";
import { Row, Col, Form, Button, ProgressBar, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../../history";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../../components/shared";
// Types
import {
  ProjectDataType,
  AirTerminationDetailsType,
  DownConductorDetailsType,
  EarthElectrodeDetailsType,
} from "../../../ts/types";
// Helpers
import { getFarmerById, updateProject } from "../../../../helpers/api";
// Data
import { InitialProjectData } from "../InitialProjectData";

const TechinicalSpecification4: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getFarmerById(projectId)
      .then((res: any) => {
        setInputData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [projectId]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [
      keyof ProjectDataType,
      (
        | keyof AirTerminationDetailsType
        | keyof DownConductorDetailsType
        | keyof EarthElectrodeDetailsType
      )
    ];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    updateProject(inputData).then((res: any) => {
      if (res.status) {
        toast.success("Project updated successfully");
        setSubmitting(false);
        history.push(`/edit-project/${projectId}/structure`);
      } else {
        toast.error("Error while updating the project");
      }
    });
  };

  return (
    <div>
      <Container className="form_container">
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Project Details" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>

        <div className="register-project m-l-5 my-5 form_cover">
          <Form onSubmit={handleOnSubmit}>
            <div className="progress-container mb-5">
              <h4 className="mb-3">Technical Specification</h4>
              <Row>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={0} className="custom-progress-bar" />
                </Col>
              </Row>
            </div>

            <h3 className="text-grey mb-4">Protection System</h3>

            <h4 className="text-grey mb-2">Air Termination System</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Air Termination Manufacturer</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder=" Manufacturer Name"
                    value={inputData.airTerminationDetails.manufacturerName}
                    onChange={handleOnChange("airTerminationDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Length of Air Termination rod above PV </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Length of Air Termination rod above PV"
                    value={inputData.airTerminationDetails.length}
                    onChange={handleOnChange("airTerminationDetails-length")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Diameter of the Air Termination </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Length of Air Termination rod"
                    value={inputData.airTerminationDetails.diameter}
                    onChange={handleOnChange("airTerminationDetails-diameter")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Material of the Air Termination </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Material of Air Termination rod "
                    value={inputData.airTerminationDetails.material}
                    onChange={handleOnChange("airTerminationDetails-material")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="text-grey mb-2">Down Conductor</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Down Conductor Manufacturer</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder=" Manufacturer Name"
                    value={inputData.downConductorDetails.manufacturerName}
                    onChange={handleOnChange("downConductorDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> CSA of Down Conductor in Sq. mm</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="CSA of Down Conductor in Sq. mm"
                    value={inputData.downConductorDetails.CSA}
                    onChange={handleOnChange("downConductorDetails-CSA")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Material of the Down Conductor </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Material of the Down Conductor"
                    value={inputData.downConductorDetails.material}
                    onChange={handleOnChange("downConductorDetails-material")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="text-grey mb-2">Earth Electrode</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Earth Electrode Manufacturer</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder=" Manufacturer Name"
                    value={inputData.earthElectrodeDetails.manufacturerName}
                    onChange={handleOnChange("earthElectrodeDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Length of Earthing Electrode </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Length of Earthing Electrode"
                    value={inputData.earthElectrodeDetails.length}
                    onChange={handleOnChange("earthElectrodeDetails-length")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Material of Earthing Electrode </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Material of Earthing Electrode"
                    value={inputData.earthElectrodeDetails.material}
                    onChange={handleOnChange("earthElectrodeDetails-material")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="form-btn-group">
              <Button variant="primary" type="submit">
                Save & Continue
              </Button>
              &ensp;
              <Button
                variant="outline-primary"
                onClick={() => history.push(`/project-status/${projectId}/installation`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>

        {submitting && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default TechinicalSpecification4;
