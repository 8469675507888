import React, { useEffect, useState } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//Components
import FilePicker from "../../components/form/FilePicker";
import GeneralInfo from "./components/GeneralInfo";
import FilesAndLog from "./components/FilesAndLog";
//History
import history from "../../../history";
//Types
import { StatusUpdateType } from "../../ts/types";
//Shared
import { LoadingOverlay } from "../../components/shared";
import { uploadFile, updateProjectStatus } from "../../../helpers/api";
import { useUserInfoContext } from "../../context/UserInfoContext";

const initialInputDataStatus: StatusUpdateType = {
  projectId: "",
  url: "",
  documentType: "agreementdoc",
};

const Registration: React.FC = () => {
  const [inputDataStatus, setInputDataStatus] = useState<StatusUpdateType>(initialInputDataStatus);

  const [loading, setLoading] = useState(false);

  const { projectId } = useParams() as any;

  const { user }: any = useUserInfoContext();

  useEffect(() => {
    if (inputDataStatus.url) {
      updateProjectStatus(inputDataStatus)
        .then((res: any) => {
          toast.success("Project updated successfully");

          history.push(`/project-status/${projectId}/initiation`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [inputDataStatus]);

  // let x: any = window.localStorage.getItem("user");

  const onFileSelect = (fileName: string) => async (file: File) => {
    setLoading(true);
    const upload = async () => {
      var bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);
      bodyFormData.append("documentType", fileName);
      bodyFormData.append("projectId", projectId);

      return uploadFile(bodyFormData).then((res: any) => {
        const values = projectId;
        let levelOne: any = "projectId";

        const prevInputData = { ...inputDataStatus } as any;
        const valuess = values.toString();
        prevInputData[levelOne] = valuess;
        setInputDataStatus(prevInputData);

        setInputDataStatus((prevData) => ({ ...prevData, [fileName]: res.data.url }));
      });
    };

    upload().finally(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      {loading && <LoadingOverlay />}
      <GeneralInfo />

      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          color: "silver",
        }}
        id="Equipments"
      >
        Project Status
      </p>
      <Row style={{ width: "50%" }}>
        <Col md={10}>
          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            Registration
          </p>
        </Col>
        <Col>
          <p
            style={{
              fontSize: "0.9rem",
              color: "silver",
            }}
          >
            1/4
          </p>
        </Col>
      </Row>
      <Row style={{ width: "50%", marginBottom: "15px" }}>
        <Col>
          <ProgressBar className="progressBar_reg" now={100} />
        </Col>
        <Col>
          <ProgressBar className="progressBar_reg" now={0} />
        </Col>
        <Col>
          <ProgressBar className="progressBar_reg" now={0} />
        </Col>
        <Col>
          <ProgressBar className="progressBar_reg" now={0} />
        </Col>
      </Row>
      <p
        style={{
          fontSize: "0.8rem",
        }}
      >
        The project has been digitized. Awaiting agreement letter between the beneficiary and the
        <br />
        Solar water Pump Company
      </p>

      {user?.role === "SUPERADMIN" && (
        <FilePicker id="url" onFileSelect={onFileSelect("url")} placeholder="Upload Agreement" />
      )}
      {!!inputDataStatus.url && (
        <div>
          <img
            src={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/File_alt_font_awesome.svg/1024px-File_alt_font_awesome.svg.png"
            }
            alt="AgreementUrl"
            width="100"
            height="100"
            className="me-2"
          />
          <p>Citizenship</p>
        </div>
      )}

      <FilesAndLog />
    </div>
  );
};

export default Registration;
