import React, { ChangeEvent, useState, useEffect } from "react";
import { Row, Col, Form, Button, ProgressBar, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../../history";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../../components/shared";
// Types
import {
  SolarArrayDetailsType,
  ProjectDataType,
  IrrigationLandDetailsType,
  AddressType,
  OtherContributionType,
  WaterSourceDetailsType,
} from "../../../ts/types";
// Helpers
import { getFarmerById, updateProject } from "../../../../helpers/api";
// Data
import { InitialProjectData } from "../InitialProjectData";

const TechinicalSpecification1: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getFarmerById(projectId).then((res: any) => {
      if (res.status) {
        setInputData(res.data);
      }
    });
  }, [projectId]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [
      keyof ProjectDataType,
      (
        | keyof AddressType
        | keyof IrrigationLandDetailsType
        | keyof OtherContributionType
        | keyof WaterSourceDetailsType
        | keyof SolarArrayDetailsType
      )
    ];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    updateProject(inputData).then((res: any) => {
      if (res.status) {
        toast.success("Project updated successfully");
        setSubmitting(false);
        history.push(`/edit-project/${projectId}/technical-specification-2`);
      } else {
        toast.error("Error while updating the project");
      }
    });
  };

  return (
    <div>
      <Container className="form_container">
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Project Details" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>

        <div className="technical-specifications register-project m-l-5 my-5 form_cover">
          <Form onSubmit={handleOnSubmit}>
            <div className="progress-container mb-5">
              <h4 className="mb-3">Technical Specification</h4>
              <Row>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={25} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={0} className="custom-progress-bar" />
                </Col>
              </Row>
            </div>

            <h4 className="text-grey mb-2">Solar Array</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.solarArrayDetails.type}
                    onChange={handleOnChange("solarArrayDetails-type")}
                  >
                    <option>Choose Solar Panel Type</option>
                    <option value="Polycrystalline">Polycrystalline</option>
                    <option value="Monocrystalline">Monocrystalline</option>
                    <option value="Amorphous Silicon">Amorphous Silicon</option>
                    <option value="Cadmium Telluride">Cadmium Telluride</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Manufacturer Name</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Manufacturer"
                    value={inputData.solarArrayDetails.manufacturerName}
                    onChange={handleOnChange("solarArrayDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Brand/ Model</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Enter brand model"
                    value={inputData.solarArrayDetails.brand}
                    onChange={handleOnChange("solarArrayDetails-brand")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Individual Module Capacity (in Wp)</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="number"
                    placeholder="Enter Individual Module Capacity (in Wp)"
                    value={inputData.solarArrayDetails.moduleCapacity}
                    onChange={handleOnChange("solarArrayDetails-moduleCapacity")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Total Modules</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Enter total modules"
                        value={inputData.solarArrayDetails.totalModules}
                        onChange={handleOnChange("solarArrayDetails-totalModules")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Total Capacity</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Total Capacity"
                        value={
                          (inputData.solarArrayDetails.totalCapacity = (
                            parseInt(inputData.solarArrayDetails.moduleCapacity, 10) *
                            parseInt(inputData.solarArrayDetails.totalModules, 10)
                          ).toString())
                        }
                        onChange={handleOnChange("solarArrayDetails-totalCapacity")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}></Col>

              <Col xs={12} lg={6}>
                <Form.Label>Serial No. Of Each Module</Form.Label>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="form-design"
                        required
                        type="text"
                        placeholder="Serial No. of Module 1"
                        value={inputData.solarArrayDetails.serial1ModuleNo}
                        onChange={handleOnChange("solarArrayDetails-serial1ModuleNo")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="form-design"
                        required
                        type="text"
                        placeholder="Serial No. of Module 2"
                        value={inputData.solarArrayDetails.serial2ModuleNo}
                        onChange={handleOnChange("solarArrayDetails-serial2ModuleNo")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        required
                        className="form-design"
                        type="text"
                        placeholder="Serial No. of Module 3"
                        value={inputData.solarArrayDetails.serial3ModuleNo}
                        onChange={handleOnChange("solarArrayDetails-serial3ModuleNo")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        required
                        className="form-design"
                        type="text"
                        placeholder="Serial No. of Module 4"
                        value={inputData.solarArrayDetails.serial4ModuleNo}
                        onChange={handleOnChange("solarArrayDetails-serial4ModuleNo")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="form-btn-group">
              <Button variant="primary" type="submit">
                Save & Continue
              </Button>
              &ensp;
              <Button
                variant="outline-primary"
                onClick={() => history.push(`/project-status/${projectId}/installation`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>

        {submitting && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default TechinicalSpecification1;
