import React from "react";
import { Container, Spinner } from "react-bootstrap";
import styles from "./Loading.module.scss";

const Loading: React.FC = () => {
  return (
    <Container className={styles.root}>
      <Spinner animation="border" variant="primary" className={styles.spinner} />
    </Container>
  );
};

export default Loading;
