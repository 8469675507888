export const HIGHWAYS = [
  { title: "Mahendra Highway", value: "Mahendra Highway" },
  { title: "Tribhuvan Highway", value: "Tribhuvan Highway" },
  { title: "Araniko Highway", value: "Araniko Highway" },
  { title: "Prithvi Highway", value: "Prithvi Highway" },
  { title: "Madan Ashrit Highway", value: "Madan Ashrit Highway" },
  { title: "B.P. Koirala Highway", value: "B.P. Koirala Highway" },
  { title: "Mechi Highway", value: "Mechi Highway" },
  { title: "Koshi Highway", value: "Koshi Highway" },
  { title: "Sagarmatha Highway", value: "Sagarmatha Highway" },
  { title: "Siddhartha Highway", value: "Siddhartha Highway" },
  { title: "Rapti Highway", value: "Rapti Highway" },
  { title: "Ratna Highway", value: "Ratna Highway" },
  { title: "Karnali Highway", value: "Karnali Highway" },
  { title: "Mahakali Highway", value: "Mahakali Highway" },
  { title: "Kathmandu Ringroad", value: "Kathmandu Ringroad" },
  { title: "Pusha Lal Highway", value: "Pusha Lal Highway" },
];
