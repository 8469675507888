import React, { ChangeEvent, useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiFolderPlus } from "react-icons/fi";
import FilePicker from "../../components/form/FilePicker";
import { LoadingOverlay } from "../../components/shared";
import { registerOrganization, uploadFile } from "../../../helpers/api";
import { InitialOrganizationData } from "./InitialOrganisationData";
import { OrganizationDataType } from "../../ts/types";
import { toast } from "react-toastify";
import DocumentIcon from "../../../assets/icons/documentIcon.png";
import history from "../../../history";
import _ from "lodash";
// import DocumentIcon

const RegisterOrganisation = () => {
  // const [fileName, setFileName] = useState("Choose file");
  // const [file, setFile] = useState<File | null>(null);
  const [inputData, setInputData] = useState<OrganizationDataType>(InitialOrganizationData);
  const [uploading, setUploading] = useState<boolean>(false);

  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      // form data
      const bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);

      return uploadFile(bodyFormData).then((res: any) => {
        if (res.status) {
          setInputData((prevData) => ({ ...prevData, [fileName]: res.data.url }));
          toast.success("File uploaded successfully");
        } else {
          toast.error("Error while uploading the file");
        }
      });
    };

    setUploading(true);
    await upload();
    setUploading(false);
  };

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    const value = e.target.value;
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [keyof OrganizationDataType, any];

    // Deep clone inputData to avoid mutating state directly
    const prevInputData = _.cloneDeep(inputData) as any;

    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);
    // setUploading(false);

    registerOrganization(inputData)
      .then((res: any) => {
        if (res.status) {
          toast.success("Organization registered successfully");
          history.push("/dashboard");
        } else {
          toast.error("Error while submitting the request.");
        }
      })
      .finally(() => setUploading(false));
  };

  return (
    <div>
      <Container>
        <h1>Register Organisation</h1>

        <div>
          <Form className="mt-5" onSubmit={handleOnSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="name"
                  value={inputData.name}
                  onChange={handleOnChange("name")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Address
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="address"
                  // onChange={(e) => {
                  //   setAddress(e.target.value);
                  // }}
                  value={inputData.address}
                  onChange={handleOnChange("address")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                First Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="lastName"
                  value={inputData.firstName}
                  onChange={handleOnChange("firstName")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Last Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="lastName"
                  value={inputData.lastName}
                  onChange={handleOnChange("lastName")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Email
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="email"
                  className="w-50"
                  name="email"
                  value={inputData.email}
                  onChange={handleOnChange("email")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Password
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="password"
                  className="w-50"
                  name="password"
                  value={inputData.password}
                  onChange={handleOnChange("password")}
                />
              </Col>
            </Form.Group>

            <Row>
              <Col xl={4} lg={2} md={4} xs={12}>
                <Form.Group as={Row} controlId="formFile">
                  <FilePicker
                    id="profileImageUrl"
                    onFileSelect={onFileSelect("logoUrl")}
                    placeholder="Upload Photo"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xl={4} lg={4} md={4} xs={12}>
                {!!inputData.logoUrl && (
                  <Row className="folder_Row">
                    <Col xl={4} lg={4} md={4} xs={12} className="folder_shift">
                      <img
                        height="59.45"
                        width="51px"
                        src={DocumentIcon}
                        alt="citizenshipUrl"
                        className="mt-2 d-block mx-auto"
                      />
                    </Col>
                    <Col xl={8} lg={8} md={8} xs={12}>
                      <p className="folder_contents info-folder-contents">
                        {" "}
                        <b>Logo</b>
                        <br />
                        ACIM0001256478.jpg
                        <br />
                        0.89 MB
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "flex-start" }}>
                <div className="d-flex pt-4" style={{ display: "flex", alignItems: "flex-start" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="bg-aepc-dark btn-lg w-min-200"
                    style={{ width: "250px", marginTop: "10px" }}
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
        {uploading && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default RegisterOrganisation;
