import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./LoadingOverlay.module.scss";

const LoadingOverlay: React.FC = () => {
  return (
    <div className={styles.root}>
      <Spinner animation="border" variant="primary" className={styles.spinner} />
    </div>
  );
};

export default LoadingOverlay;
