import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Table } from "react-bootstrap";
import {
  deleteOrganization,
  deleteSubOrganization,
  getOrganisationList,
} from "../../../helpers/api";
import { Organisation } from "../../ts/types";
import EditIcon from "../../../assets/icons/editIcon.png";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { LoadingOverlay } from "../../components/shared";
import { Colors } from "../../constants/colors";

const Organizations = () => {
  const [orgList, setOrgList] = useState<Organisation[]>([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entityType, setEntityType] = useState<"org" | "sub-org">();
  const [entityId, setEntityId] = useState<string>();

  const handleModalToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const fetchOrganizationsData = async () => {
      try {
        const { data } = await getOrganisationList();
        if (Array.isArray(data)) {
          setOrgList(data);
        } else {
          console.error("Expected an array but got:", typeof data);
        }
      } catch (err) {
        console.log("Error fetching organizations list", err);
      }
    };
    fetchOrganizationsData();
  }, []);

  const handleOnClick = (type: "org" | "sub-org", id: string) => {
    setEntityType(type);
    setEntityId(id);
    handleModalToggle();
  };
  const handleOnRemove = () => {
    if (entityType === "org" && entityId) {
      setLoading(true);
      deleteOrganization(entityId)
        .then((res: any) => {
          if (res.status) {
            toast.success("Successfully removed organization.");
            window.location.reload();
            handleModalToggle();
          } else {
            toast.error("Error removing organization!");
          }
        })
        .finally(() => setLoading(false));
    } else if (entityType === "sub-org" && entityId) {
      setLoading(true);
      deleteSubOrganization(entityId)
        .then((res: any) => {
          if (res.status) {
            toast.success("Successfully removed sub organization.");
            window.location.reload();
            handleModalToggle();
          } else {
            toast.error("Error removing sub organization!");
          }
        })
        .finally(() => setLoading(false));
    } else {
      toast.error("Error removing organization!");
    }
  };

  return (
    <Container
      fluid
      style={{
        padding: "0.8rem 1.3rem",
        fontWeight: "normal",
      }}
    >
      <Row>
        <h2> Organizations </h2>
      </Row>
      <div className="table-responsive mt-4 position-relative">
        <Table responsive>
          <thead className="farmerDetail__thead">
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orgList.length > 0 ? (
              orgList.map((organisation: Organisation, index: number) => (
                <>
                  <tr key={organisation.id} style={{ verticalAlign: "middle", fontWeight: "bold" }}>
                    <td>
                      <img style={{ width: "60px" }} src={organisation.logoUrl} alt="" />
                    </td>
                    <td>{organisation.name}</td>
                    <td>{organisation.address}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <Link to={`/organisation/${organisation.id}/editInfo`}>
                          <img src={EditIcon} alt="Edit" className="generalInfo_Icons" />{" "}
                        </Link>
                        <MdDelete
                          size={22}
                          color={Colors.RED}
                          onClick={() => handleOnClick("org", organisation.id)}
                        />
                      </div>
                    </td>
                  </tr>
                  {organisation.subOrganizations.length > 0 && (
                    <Table
                      responsive
                      style={{ border: "1px solid #f2f2f2", marginTop: 10 }}
                    >
                      <thead className="farmerDetail__thead">
                        <tr>
                          <th>Sub Organization Logo</th>
                          <th>Sub Organization Name</th>
                          <th>Sub Organization Address</th>
                          <th>Sub Organization Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {organisation.subOrganizations.map((subOrg) => (
                          <tr key={subOrg.id}>
                            <td>
                              <img style={{ width: "60px" }} src={subOrg.logoUrl} alt="" />
                            </td>
                            <td>{subOrg.name}</td>
                            <td>{subOrg.address}</td>
                            <td>
                              <div className="d-flex align-items-center justify-content-evenly">
                                <Link to={`/organisation/${subOrg.id}/editInfo?isSub=true`}>
                                  <img src={EditIcon} alt="Edit" className="generalInfo_Icons" />
                                </Link>
                                <MdDelete
                                  size={22}
                                  color={Colors.RED}
                                  onClick={() => handleOnClick("sub-org", subOrg.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </>
              ))
            ) : (
              <tr>No data found!</tr>
            )}
            <tr></tr>
          </tbody>
        </Table>
      </div>

      <Modal show={show} onHide={handleModalToggle} centered>
        <Modal.Title
          style={{
            padding: "2rem 0rem 0rem 2rem",
          }}
        >
          Remove?
        </Modal.Title>
        <Modal.Body
          style={{
            padding: "0 2rem 2rem 3rem",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <div style={{ marginTop: "1rem" }}>
            Are you sure you want to delete this{" "}
            {entityType === "sub-org" ? "sub organization" : "organization"}? This action cannot be
            undone.
          </div>
          <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end", gap: 10 }}>
            <Button
              variant="secondary"
              style={{
                fontSize: "0.9rem",
                marginRight: "5px",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
              }}
              onClick={() => handleModalToggle()}
            >
              <b>Cancel</b>
            </Button>
            <Button
              variant="primary"
              style={{
                fontSize: "0.9rem",
                marginRight: "5px",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleOnRemove()}
            >
              <b>Confirm</b>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {loading && <LoadingOverlay />}
    </Container>
  );
};

export default Organizations;
