import React from "react";
import classNames from "classnames";

interface Props {
  type?: "light" | "dark" | "white";
  size?: "small" | "medium" | "large";
}

const PageTitle: React.FC<Props> = ({ type, size }) => {
  var dividerClass = classNames({
    divider: true,
    light: !type || type === "light",
    dark: type === "dark",
    white: type === "white",
    small: !size || size === "small",
    medium: size === "medium",
    large: size === "large",
  });

  return <hr className={dividerClass} />;
};

export default PageTitle;
