import { ProjectDataType } from "../../ts/types";

export const InitialProjectData: ProjectDataType = {
  listNo: "",
  beneficiaryName: "",
  fiscalYear: "",
  lot: "",
  phoneNumber: "",
  companyName: "",
  createdAt: "",
  agreementDocUrl: "",
  workOrderDocUrl: "",
  longitude: "",
  latitude: "",
  altitude: "",
  secondaryContactNumber: "",
  address: {
    region: "",
    district: "",
    municipality: "",
    ward: "",
    tole: "",
    nearestHighway: "",
    distanceFromNearestHighway: "",
  },
  organisation: {
    id: "",
    name: "",
    address: "",
    logoUrl: "",
    subOrganizations: [],
  },
  organizationId: "",
  subOrganizationId: "",
  irrigationLandDetails: {
    landType: "Bigha",
    land: "",
  },
  recommendedBy: "",
  citizenshipNo: "",
  citizenshipUrl: "",
  landOwnershipUrl: "",
  profileImageUrl: "",
  totalCost: 0,
  totalSubsidy: 0,
  userContribution: "",
  otherContributions: {
    contribution1: "",
    contribution1Type: "",
    contribution2: "",
    contribution2Type: "",
  },
  solarArrayDetails: {
    type: "",
    manufacturerName: "",
    brand: "",
    moduleCapacity: "",
    totalModules: "",
    totalCapacity: "",
    serial1ModuleNo: "",
    serial2ModuleNo: "",
    serial3ModuleNo: "",
    serial4ModuleNo: "",
  },
  waterSourceDetails: {
    source: "",
    head: "",
    discharge: "",
  },
  pumpDetails: {
    manufacturerName: "",
    brand: "",
    serialNumber: "",
    pumpType: "",
    pumpCapacity: "",
    pumpCapacityUnit: "",
  },
  controllerDetails: {
    manufacturerName: "",
    brand: "",
    serialNumber: "",
    controllerType: "",
    controllerCapacity: "",
    controllerCapacityUnit: "",
  },
  combinerBoxDetails: {
    manufacturerName: "",
  },
  circuitBreakerDetails: {
    manufacturerName: "",
    voltageRating: "",
    currentRating: "",
  },
  spdDetails: {
    manufacturerName: "",
    brand: "",
    spdType: "",
    operatingVoltage: "",
  },
  cableDetails: {
    manufacturerName: "",
    brand: "",
    conductorMaterial: "",
    cable: "",
    uvResistance: "",
  },
  airTerminationDetails: {
    manufacturerName: "",
    length: "",
    diameter: "",
    material: "",
  },
  downConductorDetails: {
    manufacturerName: "",
    CSA: "",
    material: "",
  },
  earthElectrodeDetails: {
    manufacturerName: "",
    length: "",
    material: "",
  },
  mountingStructureDetails: {
    material: "",
    groundClearance: "",
    nonCorrosiveFastner: "",
  },
  status: "",
};
