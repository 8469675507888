import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// History
import history from "../../../history";
// Contexts
import { toggleSidebar, useLayout } from "../../context/LayoutContext";
// Assests
import Avatar from "../../../assets/img/avatar.png";
import Logo from "../../../assets/img/logo.svg";

//Helper
import { isMdScreen, isMobile } from "../../../utils/utils";
import { getOrganizationDetails } from "../../../helpers/api";

const Header: React.FC = () => {
  let x: any = window.localStorage.getItem("user");
  const monitoredBy = JSON.parse(x).name;
  const location = useLocation();
  const { dispatch, state } = useLayout();
  const isDashboard = location && location.pathname && location.pathname.includes("dashboard");

  const [organizationLogo, setOrgnizationLogo] = useState(null);

  const fetchorganizationDetails = async () => {
    const response = await getOrganizationDetails();

    if (response?.data?.Organizations) {
      setOrgnizationLogo(response?.data?.Organizations[0]?.logoUrl);
    }
  };

  useEffect(() => {
    fetchorganizationDetails();
  }, []);

  const logoutUser = (): void => {
    window.localStorage.removeItem("user");
    history.push("/sign-in");
  };

  return (
    <div className="navbar">
      <div className="title-wrap flex-grow-1">
        {isDashboard && <h1 className="d-none d-lg-block"> Dashboard </h1>}
        <div className="d-block   d-lg-none pressable" onClick={() => toggleSidebar(dispatch)}>
          <img
            src={organizationLogo || Logo}
            alt="AEPC Logo"
            className="logo"
            style={{ width: "130px" }}
          />
        </div>

        {state.isHorizontalFluid && !isMdScreen() ? (
          <div className="logo">
            <Link to="/">
              <img
                src={organizationLogo || Logo}
                alt="AEPC Logo"
                className="logo"
                style={{ width: "130px" }}
              />
            </Link>
          </div>
        ) : null}
      </div>
      <Dropdown className="user">
        <Dropdown.Toggle>
          <img src={Avatar} className="rounded-circle" height="55" width="55" alt="Admin" />
          <div className="name">{monitoredBy}</div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
