/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import classNames from "classnames";
// Views
import PageNotFound from "../views/session/PageNotFound";
// Navigations
import { RootRoutes } from "../navigations";
// History
import history from "../../history";
// Contexts
import { closeSidebar, useLayout } from "../context/LayoutContext";
// Utils
import { isLgScreen, isMdScreen } from "../../utils/utils";
// Components
import Header from "./components/Header";
import { PrivateRoute } from "../components/routes/Route";
import Sidebar from "./components/sidebar/Sidebar";
import ContentWrapper from "./components/ContentWrapper";
import Footer from "./components/Footer";

const Layout: React.FC = () => {
  const { dispatch } = useLayout();
  const location = useLocation();
  const { state } = useLayout();

  useEffect(() => {
    // close sidebar on route change in mobile
    const listenOnRouteChange = history.listen((location, action) => {
      if (isLgScreen()) {
        closeSidebar(dispatch);
      }
    });
    return () => {
      listenOnRouteChange();
    };
  }, [location]);

  return (
    <div className="app">
      <Sidebar />

      <div className={classNames("main", { "horizontal-fluid": state.isHorizontalFluid })}>
        <Header />

        <ContentWrapper>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            {RootRoutes.map((each, idx) => {
              const { path, component, ...rest } = each;
              return (
                <PrivateRoute
                  key={`route-${idx}`}
                  exact
                  path={path}
                  component={component}
                  {...rest}
                />
              );
            })}
            <Route component={PageNotFound} />
          </Switch>
        </ContentWrapper>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
