import React, { MouseEvent, ReactNode, useRef } from "react";

import styles from "./CustomModal.module.scss";

import crossIcon from "../../../assets/icons/cross.svg";

interface CustomModalProps {
  hideAlert: () => void;
  children: ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({ children, hideAlert }) => {
  const handleAlertClose = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement) !== modalRef.current) return;

    hideAlert();
  };

  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.root} onClick={handleAlertClose} ref={modalRef}>
      <div className={styles.alertBox}>
        <img src={crossIcon} className={styles.alertCross} alt="Close" onClick={hideAlert} />
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
