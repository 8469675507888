import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Form,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  CaretDownFill,
  CaretUpFill,
  ChevronDown,
  FileX,
  GeoAlt,
  Search,
  Telephone,
} from "react-bootstrap-icons";
import { BsFillCircleFill } from "react-icons/bs";
import ReactPaginate from "react-paginate";
// History
import history from "../../../history";
// Components
import { Divider, LoadingOverlay } from "../../components/shared";
import resetFilterBtn from "./resetFilterBtn";
// Helpers
import {
  dashboard,
  getProjectByStatus,
  filterProjects,
  searchProjectByName,
  getProjectsByPagination,
} from "../../../helpers/api";
// Storage
import { Province, getDistrictByProvince } from "../../../storage/geo-nepal";
// Ts
import { ProjectStates } from "../../ts/enums";
import { DetailedFilterDataType } from "../../ts/types";
import { formatTimeDuration } from "../../../helpers/dateTime";
import { useUserInfoContext } from "../../context/UserInfoContext";
interface MeterDataInterface {
  meterId: string;
  receivedAt: string;
}
interface ProjectInterface {
  listNo: string;
  lot: string;
  farmerDetails: string;
  meterDetails: string;
  projectStage: string;
  activityStatus: string;
  beneficiaryName: string;
  address: {
    district: string;
  };
  phoneNumber: string;
  projectStatus: string;
  fiscalYear: string;
  status: string;
  id: string;
  projectMeter: {
    meterData: MeterDataInterface[];
    id: string;
    projectId: string;
    status: string;
    hardwareId: string;
    phoneNumber: string;
    lastReceivedAt: string;
  };
}
interface DashboardData {
  activeCount: number;
  totalCount: number;
  inActiveCount: number;
  disabledCount: number;
  approvedCount: number;
  dispatchedCount: number;
  installedCount: number;
  initializedCount: number;
  data: ProjectInterface[];
}

let detailedFilterData: DetailedFilterDataType = {
  status: "",
  meterStatus: "",
  region: "",
  district: "",
};
const activityIndicator = {
  Active: "#0AD61E",
  Inactive: "#D60A2E",
  "Not Installed": "#CCCCCC",
  "N/A": "#FFFFFF",
};
const PROJECTS_BATCH = 10;

const Dashboard: React.FC = () => {
  const [projects, setProjects] = useState<ProjectInterface[] | null>(null);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [sortDistrict, setSortDistrict] = useState("");
  const [projectcount, setProjectsCount] = useState("");
  const [selectedProjectStatus, setSeletectedProjectStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [showDetailedFilter, setShowDetailedFilter] = useState(false);
  const [sort, setSort] = useState<"ASC" | "DESC">("DESC");
  const [projectNameSearchKey, setProjectNameSearchKey] = useState<string>("");

  const { user }: any = useUserInfoContext();

  const isFirstRender = useRef(true);

  //fetch dashboard data
  useEffect(() => {
    dashboard()
      .then((res: any) => {
        if (res.status) {
          setDashboardData(res.data);
          setProjects(res.data.data);
          setProjectsCount(res.data.totalCount);
        }
      })

      .finally(() => {
        setLoading(false);
      });
  }, []);

  //navigate to register project
  const registerNewProject = () => {
    history.push("/register-project");
  };

  //handle get projects by status
  const onGetProjectByStatus = (status: string) => {
    setLoading(true);
    getProjectByStatus(status)
      .then((res: any) => {
        if (res.status) {
          setProjects(res.data.data);
          setProjectsCount(res.data.totalCount);
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };

  //handle detailed filter
  const statusSort = (value: string, key: string) => {
    setLoading(true);
    if (key === "status") {
      detailedFilterData.status = value;
    }

    if (key === "meterStatus") {
      detailedFilterData.meterStatus = value;
    }

    if (key === "district") {
      detailedFilterData.district = value;
    }

    if (key === "province") {
      detailedFilterData.region = value;
    }

    if (key === "clear") {
      detailedFilterData.region = value;
      detailedFilterData.district = value;
      detailedFilterData.status = value;
      setSeletectedProjectStatus("");
    }

    filterProjects(detailedFilterData)
      .then((res: any) => {
        if (res.status) {
          setProjects(res.data.data);
          setProjectsCount(res.data.totalCount);
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };

  // handle search project by name
  const handleSearchProjectByName = (searchValue: string) => {
    searchProjectByName(searchValue, sort).then((res: any) => {
      if (res.status) {
        setProjects(res.data.data);
        setProjectsCount(res.data.totalCount);
      }
    });

    setSeletectedProjectStatus("");
  };

  //pagination
  const pageCount = Math.ceil(parseInt(projectcount, 10) / PROJECTS_BATCH);

  const changePage = ({ selected }: { selected: number }) => {
    let offset;
    if (selected === 0) {
      offset = 0;
      fetchPagination(offset);
    } else {
      offset = selected * PROJECTS_BATCH;
      fetchPagination(offset);
    }
  };

  const fetchPagination = (currentPage: number) => {
    setLoading(true);
    getProjectsByPagination(currentPage)
      .then((res: any) => {
        setProjects(res.data.data);
      })

      .finally(() => setLoading(false));
  };

  //check activity (active/inactive)
  const checkActivity = (meterDate: any) => {
    if (!meterDate) {
      return "Not Installed";
    } else if (!meterDate?.lastReceivedAt) {
      return "N/A";
    }

    let sentStatusData = "N/A";
    const lastReceivedDate: any = meterDate.lastReceivedAt;
    // receivedData?.map((time: any) => {
    //   const meterLastReceivedAt = time.createdAt;
    //   const currentGMTTime = new Date().getTime();
    //   const timeSinceLastActivity = currentGMTTime - new Date(meterLastReceivedAt).getTime();
    //   const minuteFinal = Math.round(timeSinceLastActivity / 1000 / 60);
    //   if (minuteFinal < 60) {
    //     sentStatusData = "Active";
    //   } else {
    //     sentStatusData = "Inactive";
    //   }
    // });
    const currentGMTTime = new Date().getTime();
    const timeSinceLastActivity = currentGMTTime - new Date(lastReceivedDate).getTime();
    const minuteFinal = Math.round(timeSinceLastActivity / 1000 / 60);
    if (minuteFinal < 60) {
      sentStatusData = "Active";
    } else {
      sentStatusData = "Inactive";
    }

    return sentStatusData;
  };

  const activityTime = (meterDate: any) => {
    let forTimeData = "";
    if (meterDate) {
      const lastReceivedDate: any = meterDate.lastReceivedAt;
      // receivedData?.map((time: any) => {
      //   const meterLastReceivedAt = time.createdAt;
      //   const currentGMTTime = new Date().getTime();
      //   const timeSinceLastActivity = currentGMTTime - new Date(meterLastReceivedAt).getTime();
      //   const minuteFinal = Math.round(timeSinceLastActivity / 1000 / 60);
      //   forTimeData = formatTimeDuration(timeSinceLastActivity);
      // });
      const currentGMTTime = new Date().getTime();
      const timeSinceLastActivity = currentGMTTime - new Date(lastReceivedDate).getTime();
      forTimeData = formatTimeDuration(timeSinceLastActivity);
    }
    return forTimeData;
  };

  const currentLink = (farmer: ProjectInterface) => {
    let rootUrl = `/project-status/${farmer.id}/:link`;
    switch (farmer.status) {
      case ProjectStates.REGISTERED:
        rootUrl = rootUrl.replace(":link", "registration");
        break;
      case ProjectStates.INITIALIZD:
        rootUrl = rootUrl.replace(":link", "initiation");
        break;
      case ProjectStates.INSTALLED:
        rootUrl = rootUrl.replace(":link", "installation");
        break;
      case ProjectStates.APPROVED:
        rootUrl = rootUrl.replace(":link", "installation");
        break;
      case ProjectStates.DISPATCHED:
        rootUrl = rootUrl.replace(":link", "operational");
        break;
      case ProjectStates.OPERATIONAL:
        rootUrl = rootUrl.replace(":link", "operational");
        break;
      default:
        rootUrl = rootUrl.replace(":link", "registration");
        break;
    }
    return rootUrl;
  };

  const renderProjectStatus = ({ projectMeter }: ProjectInterface) => {
    const activity = checkActivity(projectMeter);
    //@ts-ignore
    const activityIndicatorColor = activityIndicator?.[activity];

    return (
      <div className="d-flex">
        <div className="me-2">
          <BsFillCircleFill size={8} style={{ color: activityIndicatorColor }} />
        </div>
        <div>
          {checkActivity(projectMeter)}
          <br />
          <span>{projectMeter?.lastReceivedAt && activityTime(projectMeter)}</span>
        </div>
      </div>
    );
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(e.target.value as "ASC" | "DESC");
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }
    handleSearchProjectByName(projectNameSearchKey);
  }, [sort, projectNameSearchKey]);

  return (
    <div>
      {loading && <LoadingOverlay />}

      <Container fluid>
        <Row className="cards-container mb-4 xs-child-gap-large">
          <Col xs={12} lg={5}>
            <Card className="overview-card p-2 h-100 bg-aepc">
              <Card.Body>
                <Card.Title>Status Overview</Card.Title>
                <Row className="mb-3">
                  <Col>
                    <h2 className="mega-figure">{dashboardData?.totalCount}</h2>
                    <p style={{ marginTop: "-10px" }} className="stat-title  ">
                      Total Projects
                    </p>
                  </Col>
                  <Col>
                    <h2 className="mega-figure">{dashboardData?.activeCount}</h2>
                    <p style={{ marginTop: "-10px" }} className="stat-title">
                      Active Projects
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={7}>
            <Card className="ms-lg-3 p-2 h-100 justify-content-end">
              <Row className=" mt-2 xs-child-gap justify-content-end">
                <ToggleButtonGroup
                  className="Btn-Blue-BG2 register_btnGroup  row justify-content-end"
                  type="radio"
                  name="options"
                >
                  {user?.role === "SUPERADMIN" && (
                    <Col sm={4} className="register_btn ">
                      <Button
                        className="toggle_btns"
                        variant="outline-primary "
                        onClick={registerNewProject}
                      >
                        Register New Project
                      </Button>
                    </Col>
                  )}
                </ToggleButtonGroup>
              </Row>
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>

        <div className="filters mt-4">
          <Row className="QuickFilters mb-4 xs-child-gap">
            <Col xs={12} lg={6}>
              <h6>Quick Filters</h6>
              <Row className="xs-child-gap">
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  className="Btn-Blue-BG quickFilter_btnGroup row"
                >
                  <Col sm={4} className="quickFilter_btn">
                    <ToggleButton
                      key={1}
                      id={`radio-1`}
                      type="radio"
                      name="radio"
                      value="1"
                      active={"registered" === selectedProjectStatus}
                      className="btn-light-blue mb-1 "
                      // style={{ width: "185px" }}
                      onClick={() => {
                        onGetProjectByStatus("registered");
                        setSeletectedProjectStatus("registered");
                      }}
                    >
                      Registered Projects
                    </ToggleButton>
                  </Col>
                  <Col sm={4} className="quickFilter_btn">
                    <ToggleButton
                      id={`radio-2`}
                      type="radio"
                      name="radio2"
                      value="2"
                      active={"initialized" === selectedProjectStatus}
                      onClick={() => {
                        onGetProjectByStatus("initialized");
                        setSeletectedProjectStatus("initialized");
                      }}
                      className="btn-light-blue mb-1"
                    // style={{ width: "185px" }}
                    >
                      Initiated Projects
                    </ToggleButton>
                  </Col>
                  <Col sm={4} className="quickFilter_btn">
                    <ToggleButton
                      id={`radio-3`}
                      type="radio"
                      name="radio3"
                      value="3"
                      active={"installed" === selectedProjectStatus}
                      className="btn-light-blue mb-1 "
                      // style={{ width: "185px" }}
                      onClick={() => {
                        onGetProjectByStatus("installed");
                        setSeletectedProjectStatus("installed");
                      }}
                    >
                      Installed Projects
                    </ToggleButton>
                  </Col>
                </ToggleButtonGroup>
              </Row>
            </Col>
            <Col xs={12} lg={6}>
              <Row className="xs-child-gap">
                <Col xs={12} lg={8} className="search">
                  <h6>Search</h6>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      className="search-bar"
                      style={{ borderRadius: "9px 0px 0px 9px", height: "44px" }}
                      placeholder="Search Projects"
                      onChange={(e: any) => {
                        setProjectNameSearchKey(e.target.value);
                      }}
                    />
                    <InputGroup.Text style={{ borderRadius: "0px 9px 9px 0px" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="detailed-filters">
                    <h6>Sort By</h6>
                    <Form.Select
                      style={{ borderRadius: "9px", height: "44px" }}
                      value={sort}
                      onChange={(e) => handleSortChange(e)}
                    >
                      <option value="ASC">Ascending Date</option>
                      <option value="DESC">Descending Date</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="detailed-filter-wrapper">
            <div className="filters-header" onClick={() => setShowDetailedFilter((val) => !val)}>
              <h6>
                {showDetailedFilter ? (
                  <>
                    Hide Detailed Filter <CaretUpFill />
                  </>
                ) : (
                  <>
                    Show Detailed Filter <CaretDownFill />
                  </>
                )}
              </h6>
            </div>

            {showDetailedFilter && (
              <div className="detailed-filters mt-2 mb-4">
                <Row>
                  <Col xs={12} lg={4}>
                    <Form.Group>
                      <Form.Label className="text-grey">Location</Form.Label>
                      <div className="d-flex">
                        <Form.Select
                          style={{ borderRadius: "9px", marginRight: "15px" }}
                          id="resetFilterProvince"
                          onChange={(e: any) => {
                            statusSort(e.target.value, "province");
                            setSortDistrict(e.target.value);
                          }}
                        >
                          <option value="">Province</option>
                          {Province.map(({ name, id }) => (
                            <option value={name} key={id}>
                              {name}
                            </option>
                          ))}
                        </Form.Select>
                        &ensp;
                        <Form.Select
                          style={{ borderRadius: "9px" }}
                          id="resetFilterDistrict"
                          onChange={(e: any) => {
                            statusSort(e.target.value, "district");
                          }}
                        >
                          <option value="">District</option>
                          {getDistrictByProvince(sortDistrict)?.map((datas: any) => (
                            <option value={datas.name} key={datas.id}>
                              {datas.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={2}>
                    <Form.Group>
                      <Form.Label className="text-grey">Project Status</Form.Label>
                      <div className="d-flex">
                        <Form.Select
                          style={{ borderRadius: "9px" }}
                          id="resetFilterStatus"
                          onChange={(e: any) => {
                            statusSort(e.target.value, "status");
                          }}
                        >
                          <option value="">Status</option>
                          <option value="registered">Registered</option>
                          <option value="initialized">Initialized</option>
                          <option value="installed">Installed</option>
                          <option value="Operational">Operational</option>
                          <option value="Approved">Approved</option>
                          <option value="dispatched">Dispatched</option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={2}>
                    <Form.Group>
                      <Form.Label className="text-grey">Activity Status</Form.Label>
                      <Form.Select
                        style={{ borderRadius: "9px" }}
                        id="resetFilterActivity"
                        onChange={(e: any) => {
                          statusSort(e.target.value, "meterStatus");
                        }}
                      >
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={2} className="align-self-center">
                    <Button
                      className="btn-light-blue mt-4"
                      id="btnReset"
                      value="Reset"
                      onClick={() => {
                        resetFilterBtn();
                        statusSort("", "clear");
                      }}
                    >
                      Clear All Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>

        <div className="table-responsive mt-4 position-relative">
          <Table responsive>
            <thead className="farmerDetail__thead">
              <tr>
                <th> List No.</th>
                <th> Farmer Details</th>
                <th> Fiscal Year</th>
                <th> Lot</th>
                <th> Meter Details</th>
                <th> Project Stage</th>
                <th> Activity Status</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {projects?.length ? (
                projects.map((each, index) => (
                  <tr key={index} className="farmerDetail__tbody">
                    <td>{each.listNo}</td>
                    <td>
                      {each.beneficiaryName}
                      <br />
                      <span>
                        <GeoAlt />
                        {each.address.district}
                      </span>
                      <br />
                      <span>
                        <Telephone />
                        {each.phoneNumber}
                      </span>
                    </td>
                    <td>{each.fiscalYear}</td>
                    <td> {each.lot}</td>
                    <td>
                      {each.projectMeter?.hardwareId || "N/A"}
                      <br />
                      {each.projectMeter?.phoneNumber || "N/A"}
                    </td>
                    <td>{each.status}</td>
                    <td>{renderProjectStatus(each)}</td>
                    <td>
                      <div className="d-flex justify-content-between">
                        {/* <Row className="view_projectBtnGroup"> */}
                        {/* <Col sm={12} xl={6} className="view_projectBtn"> */}
                        <Link to={currentLink(each)} style={{ marginRight: "5px" }}>
                          <Button
                            size="sm"
                            className="bg-aepc-dark px-3 py-2 mt-1  "
                            style={{ borderRadius: "9px" }}
                          >
                            View Project
                          </Button>
                        </Link>
                        {/* </Col> */}

                        {/* <Col sm={12} xl={6} className=" view_projectBtn"> */}
                        {each.projectMeter ? (
                          <Link to={`/meter-details/${each.id}`} className="mr-1">
                            <Button
                              size="sm"
                              variant="outline-primary px-3 py-2 mt-1  "
                              style={{ borderRadius: "9px" }}
                            >
                              View Meter
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            size="sm"
                            variant="outline-primary px-3 py-2 mt-1"
                            style={{ borderRadius: "9px" }}
                            disabled
                          >
                            View Meter
                          </Button>
                        )}
                      </div>
                      {/* </Col> */}
                      {/* </Row> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>No Project Available</td>
                </tr>
              )}
            </tbody>
          </Table>

          {projects?.length ? (
            <ReactPaginate
              previousLabel={""}
              nextLabel={""}
              pageCount={pageCount}
              onPageChange={changePage}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              // previousLinkClassName={"page-link"}
              containerClassName={"pagination justify-content-center"}
              // previousClassName={"page-item"}
              // nextLinkClassName={"page-link"}
              // nextClassName={"page-item"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"active activePagination"}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              breakLabel={"..."}
              breakClassName={"break-label"}
            />
          ) : null}
        </div>
      </Container >
    </div >
  );
};

export default Dashboard;
