export const BASE_URL = process.env.REACT_APP_API;

export const urls = {
  login: `${BASE_URL}/auth`,
  refreshToken: `${BASE_URL}/refreshToken`,
  uploadFile: `${BASE_URL}/upload-file`,
  project: `${BASE_URL}/project`,
  organization: `${BASE_URL}/organization`,
  subOrganization: `${BASE_URL}/sub-organization`,
  getProjectDetails: `${BASE_URL}/project/find/:projectId`,
  benificiary: `${BASE_URL}/project/beneficary`,
  meter: `${BASE_URL}/meter`,
  meterDetails: {
    MONTHLY: `${BASE_URL}/meter/meter-data/graph/monthly?filter=:filter&meterId=:meterId&from=:from&to=:to`,
    YEARLY: `${BASE_URL}/meter/meter-data/graph/yearly?filter=:filter&meterId=:meterId&from=:from&to=:to`,
    METER_ACTIVITY: `${BASE_URL}/project/:projectId/meter`,
    ENABLE_PUMP: `${BASE_URL}/meter/enable`,
    DISABLE_PUMP: `${BASE_URL}/meter/disable`,
  },
};
