import { useEffect, useState } from "react";
import { Button, Container, Row, Col, Table, ProgressBar } from "react-bootstrap";

// import WorkOrderUpload from "./components/WorkOrderUpload";
import { useParams } from "react-router-dom";
import FilePicker from "../../components/form/FilePicker";
import { toast } from "react-toastify";

//History
import history from "../../../history";

//Components
import GeneralInfo from "./components/GeneralInfo";
import FilesAndLog from "./components/FilesAndLog";

//Types
import { StatusUpdateType, MeterActivityStatusProps } from "../../ts/types";

//Helpers
import { uploadFile, getMeterActivityStatus, updateProjectStatus } from "../../../helpers/api";
import { formatTimeDuration, getCurrentGmtTime } from "../../../helpers/dateTime";

//Shared
import { LoadingOverlay } from "../../components/shared";

// Assests
import EditIcon from "../../../assets/icons/editIcon.png";
import { useUserInfoContext } from "../../context/UserInfoContext";

const initialInputDataStatus: StatusUpdateType = {
  projectId: "",
  url: "",
  documentType: "workOrder",
};

const Initiation = () => {
  const [inputDataStatus, setInputDataStatus] = useState<StatusUpdateType>(initialInputDataStatus);
  const [meterStatus, setMeterStatus] = useState({} as MeterActivityStatusProps);
  const [loading, setLoading] = useState<boolean>(true);

  const { user }: any = useUserInfoContext();

  const { projectId } = useParams() as any;
  useEffect(() => {
    getMeterActivityStatus(projectId)
      .then((res) => {
        setMeterStatus(res.data);
      })
      .finally(() => setLoading(false));
  }, [projectId]);

  useEffect(() => {
    if (inputDataStatus.url) {
      updateProjectStatus(inputDataStatus)
        .then((res: any) => {
          toast.success("Project updated successfully");
          history.push(`/project-status/${projectId}/installation`);
        })

        .finally(() => setLoading(false));
    }
  }, [inputDataStatus]);

  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      var bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);
      bodyFormData.append("documentType", fileName);
      bodyFormData.append("projectId", projectId);

      return uploadFile(bodyFormData).then((res: any) => {
        const values = projectId;
        let levelOne: any = "projectId";

        const prevInputData = { ...inputDataStatus } as any;
        const valuess = values.toString();

        prevInputData[levelOne] = valuess;
        setInputDataStatus(prevInputData);

        setInputDataStatus((prevData) => ({ ...prevData, [fileName]: res.data.url }));
      });
    };
    try {
      setLoading(true);
      await upload();
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {loading && <LoadingOverlay />}
      <GeneralInfo />
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          color: "silver",
        }}
        id="Equipments"
      >
        Project Status
      </p>
      <Row style={{ width: "50%" }}>
        <Col md={10}>
          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            Initiation
          </p>
        </Col>
        <Col>
          <p
            style={{
              fontSize: "0.9rem",
              color: "silver",
            }}
          >
            2/4
          </p>
        </Col>
      </Row>
      <Row style={{ width: "50%", marginBottom: "15px" }}>
        <Col>
          <ProgressBar style={{ height: "10px" }} now={100} />
        </Col>
        <Col>
          <ProgressBar style={{ height: "10px" }} now={100} />
        </Col>
        <Col>
          <ProgressBar style={{ height: "10px" }} now={0} />
        </Col>
        <Col>
          <ProgressBar style={{ height: "10px" }} now={0} />
        </Col>
      </Row>
      <p
        style={{
          fontSize: "0.8rem",
        }}
      >
        The project has been initiated. The beneficiary and the SWO company have signed an
        <br />
        agreement. Awaiting work order and Meter Creation
      </p>
      {user?.role === "SUPERADMIN" && (
        <FilePicker id="url" onFileSelect={onFileSelect("url")} placeholder="Upload Workorder" />
      )}{" "}
      <hr
        style={{
          backgroundColor: "silver",
          border: "1px silver solid",
          margin: "2rem 0",
        }}
      />
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          color: "silver",
        }}
      >
        Equipment
      </p>
      <Container
        fluid
        style={{
          border: "1.5px solid silver",
          borderRadius: "10px",

          fontWeight: "normal",
        }}
      >
        <Row
          style={{
            borderRadius: "10px",
            backgroundColor: "rgb(230, 230, 230)",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Col xl={10} lg={8} md={6} xs={6} className="pt-2">
            <p>
              <b>Meter Details</b>
            </p>
          </Col>
          {user?.role === "SUPERADMIN" && (
            <Col xl={2} lg={4} md={6} xs={6}>
              <div>
                {meterStatus.projectMeter && (
                  <Button
                    variant="outline-primary"
                    style={{ fontSize: "0.8rem", backgroundColor: "white", float: "right" }}
                    onClick={() => {
                      history.push(`/configure-meter/${projectId}`);
                    }}
                  >
                    <img src={EditIcon} className="generalInfo_Icons" />
                    <b style={{ color: "rgb(3, 146, 255)" }}>Configure Meter</b>
                  </Button>
                )}
                <Button
                  variant="outline-primary"
                  style={{ fontSize: "0.8rem", backgroundColor: "white", float: "right" }}
                  onClick={() => {
                    const action = !meterStatus.projectMeter ? "create-meter" : "edit-meter";
                    history.push(`/${action}/${projectId}`);
                  }}
                >
                  <img src={EditIcon} className="generalInfo_Icons" />
                  <b style={{ color: "rgb(3, 146, 255)" }}>
                    {!meterStatus.projectMeter ? "Create Meter" : "Edit Meter"}
                  </b>
                </Button>
              </div>
            </Col>
          )}
        </Row>
        <Table>
          <thead className="farmerDetail__thead">
            <tr
              style={{
                borderBottom: "1.5px solid silver",
                fontWeight: "normal",
              }}
            >
              <th>Meter Number</th>

              <th>Meter ID</th>

              <th>Meter Status</th>

              <th>Last Active</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style={{
                borderBottom: "1.5px solid white",
                fontWeight: "normal",
              }}
            >
              <td>{meterStatus.projectMeter?.phoneNumber || "N/A"}</td>
              <td>{meterStatus.projectMeter?.hardwareId || "N/A"}</td>
              <td>{meterStatus.projectMeter?.status || "N/A"}</td>
              <td>
                {formatTimeDuration(
                  getCurrentGmtTime() -
                    new Date(meterStatus.projectMeter?.meterData[0]?.receivedAt).getTime()
                ) || "N/A"}
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <FilesAndLog />
    </div>
  );
};

export default Initiation;
