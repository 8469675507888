import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { BarChartFill } from "react-bootstrap-icons";
import classNames from "classnames";
// Contexts
import { useLayout } from "../../../context/LayoutContext";
// Components
import SidebarLink from "./components/SidebarLink";
// Assests
import Logo from "../../../../assets/img/logo.svg";
import { useUserInfoContext } from "../../../context/UserInfoContext";
import { getOrganizationDetails } from "../../../../helpers/api";

interface MenuItem {
  id: number;
  label: string;
  link?: string;
  icon: JSX.Element;
  alternativeLink?: { label: string; link: string }[];
}
const MENUS: MenuItem[] = [
  {
    id: 0,
    label: "Projects",
    link: "/dashboard",
    icon: <BarChartFill />,
  },
  {
    id: 1,
    label: "Organisation",
    // link: "/dashboard",
    icon: <BarChartFill />,
    alternativeLink: [
      {
        label: "Current Available Organisation",
        link: "/organization",
      },
      {
        label: "Create New Organisation",
        link: "/register-organisation",
      },
      {
        label: "Create New Sub-Organisation",
        link: "/register-sub-organisation",
      },
    ],
  },
];

const Sidebar: React.FC = () => {
  const { state } = useLayout();
  const { user }: any = useUserInfoContext();
  const userRole = user.role;
  const [filteredMenus, setFilteredMenus] = useState<MenuItem[]>([]);

  const [organizationLogo, setOrgnizationLogo] = useState(null);

  const getMenuBasedOnRole = (role: string) => {
    switch (role) {
      case "SUPERADMIN":
        return MENUS; 
      default:
        return MENUS.filter((menu) => menu.id === 0);
    }
  };

  useEffect(() => {
    if (userRole !== undefined && userRole !== "") {
      setFilteredMenus(getMenuBasedOnRole(userRole));
    } else {
      window.location.reload();
    }
  }, [userRole]);

  const fetchorganizationDetails = async () => {
    const response = await getOrganizationDetails();

    if (response?.data?.Organizations) {
      setOrgnizationLogo(response?.data?.Organizations[0]?.logoUrl);
    }
  };

  useEffect(() => {
    fetchorganizationDetails();
  }, []);

  // useEffect(() => {
  //   const role = user?.role || localStorage.getItem("userRole");
  //   if (role) {
  //     setUserRole(role);
  //   } else {
  //     console.warn("User role is not available");
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (userRole) {
  //     setFilteredMenus(getMenuBasedOnRole(userRole));
  //   }
  // }, [userRole]);

  return (
    <div className={classNames("sidebar", { active: state.isSidebarOpened })}>
      <div className="logo">
        <Link to="/">
          <img
            src={organizationLogo || Logo}
            alt="AEPC Logo"
            className="img-fluid"
            style={{ width: "130px" }}
          />
        </Link>
      </div>
      <div className="list">
        {filteredMenus.map((link) => (
          <SidebarLink key={link.id} {...link} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
