import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// Storage
import {
  Province,
  getDistrictByProvince,
  getMunicipalitiesByDistrict,
} from "../../../storage/geo-nepal";
// History
import history from "../../../history";
// Helpers
import { getOrganisationList, getSubOrganizations, uploadFile } from "../../../helpers/api";
import { getFarmerById, updateProject } from "../../../helpers/api";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../components/shared";
import FilePicker from "../../components/form/FilePicker";
// Types
import { ProjectDataType, SubOrganizationDataType } from "../../ts/types";
// Data
import { InitialProjectData } from "./InitialProjectData";

// Assests
import DocumentIcon from "../../../assets/icons/documentIcon.png";
//helper
import { FISCAL_YEARS } from "../../../helpers/fiscalYear";
import _ from "lodash";

export interface Organisation {
  id: string;
  name: string;
  address: string;
  logoUrl: string;
  subOrganizations: Organisation[]; // Assuming subOrganizations is an array of Organisation
}

const EditInfo: React.FC = () => {
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [uploading, setUploading] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();

  const [orgList, setOrgList] = useState<Organisation[]>([]);
  const [subOrgList, setSubOrgList] = useState<Organisation[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<string>("");
  const [selectedSubOrganisation, setSelectedSubOrganisation] = useState<string>("");

  const fetchOrgList = useCallback(async () => {
    try {
      const { data } = await getOrganisationList();
      if (Array.isArray(data)) {
        setOrgList(data);
      } else {
        console.error("Expected an array but got:", typeof data);
      }
    } catch (error) {
      console.error("Error fetching organization list:", error);
    }
  }, []);

  const getSubOrgList = useCallback(async () => {
    const organisationName = selectedOrganisation;

    if (organisationName) {
      try {
        const data = await getSubOrganizations(organisationName);
        if (Array.isArray(data)) {
          setSubOrgList(data);
        } else {
          console.error("Expected an array but got:", typeof data);
        }
      } catch (error) {
        console.error("Error fetching sub-organizations:", error);
      }
    } else {
      // Reset sub-org list if no organization is selected
      setSubOrgList([]);
    }
  }, [inputData.subOrganizationId, selectedOrganisation]);

  const getOrgName = () => {
    const organisationName = orgList.find((org) =>
      org.id === inputData.organizationId ? org.name : ""
    );
    if (organisationName) {
      const name = organisationName?.name;
      setSelectedOrganisation(name);
    }
  };
  const getSubOrgName = () => {
    const subOrganisationName = subOrgList.find((org) =>
      org.id === inputData.subOrganizationId ? org.name : ""
    );
    if (subOrganisationName) {
      const name = subOrganisationName?.name;
      setSelectedSubOrganisation(name);
    } else {
      setSelectedSubOrganisation("");
    }
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    getFarmerById(projectId).then((res: any) => {
      setInputData(res.data);
    });
  }, [projectId]);

  useEffect(() => {
    getOrgName();
  }, [orgList, inputData.organizationId]);

  useEffect(() => {
    if (orgList) {
      getSubOrgList();
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    getSubOrgName();
  }, [orgList, inputData.subOrganizationId, subOrgList]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    const value = e.target.value;

    // Deep clone inputData to avoid mutating state directly
    const prevInputData = _.cloneDeep(inputData) as any;

    if (inputKey === "organizationId") {
      // Find the organization ID based on the selected organization name

      const selectedOrg = orgList.find((org) => org.name === value);

      if (selectedOrg) {
        setSelectedOrganisation(selectedOrg?.name);
        prevInputData.organizationId = selectedOrg?.id;

        // Update sub-organization list based on selected organization
        const filteredSubOrgList = selectedOrg.subOrganizations || [];
        setSubOrgList(filteredSubOrgList);

        // If no sub-organizations, set subOrganizationId to blank
        if (filteredSubOrgList.length === 1) {
          const singleSubOrgId = filteredSubOrgList[0].id;
          setSelectedSubOrganisation(filteredSubOrgList[0].name);
          prevInputData.subOrganizationId = singleSubOrgId;
        } else {
          setSelectedSubOrganisation(""); // Clear selected sub-organisation
          prevInputData.subOrganizationId = ""; // Clear subOrganizationId
        }
      } else {
        setSelectedOrganisation("");
        prevInputData.organizationId = value;
      }
    } else if (inputKey === "subOrganizationId") {
      const selectedSubOrg = subOrgList.find((org) => org.name === value);
      // const selectedSubOrg = subOrgList.find((org) => org.name === selectedSubOrganisation);
      if (selectedSubOrg) {
        setSelectedSubOrganisation(selectedSubOrg?.name);
        prevInputData.subOrganizationId = selectedSubOrg?.id;
      } else {
        setSelectedSubOrganisation("");
        prevInputData.subOrganizationId = "";
      }
    } else {
      let [levelOneKey, levelTwoKey] = inputKey.split("-") as [keyof SubOrganizationDataType, any];

      if (levelTwoKey) {
        prevInputData[levelOneKey][levelTwoKey] = value;
      } else {
        prevInputData[levelOneKey] = value;
      }
    }

    setInputData(prevInputData);
  };

  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      // form data
      const bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);

      return uploadFile(bodyFormData).then((res: any) => {
        if (res.status) {
          setInputData((prevData) => ({ ...prevData, [fileName]: res.data.url }));
          toast.success("File uploaded successfully");
        } else {
          toast.error("Error while uploading the file");
        }
      });
    };

    setUploading(true);
    await upload();
    setUploading(false);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);

    updateProject(inputData).then((res: any) => {
      toast.success("Project updated successfully");
      setUploading(false);
      history.push(`/project-status/${projectId}/registration`);
    });
  };

  const getFilteredDistricts = () => {
    const region = inputData.address.region;
    if (!!region) {
      return getDistrictByProvince(region);
    }
    return [];
  };

  const getFilteredMunicipalities = () => {
    const district = inputData.address.district;
    if (!!district) {
      return getMunicipalitiesByDistrict(district);
    }

    return [];
  };

  const getIrrigationLandArea = (landType: string) => {
    if (landType === inputData.irrigationLandDetails.landType) {
      return inputData.irrigationLandDetails.land.split("-");
    } else {
      return ["", ""];
    }
  };

  return (
    <div>
      <Container style={{ width: "90%", marginRight: "150px" }}>
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Edit Project Info" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>

        <div className="register-project m-lg-5">
          <Form onSubmit={handleOnSubmit}>
            <Row>
              <Col xs={4} lg={2}>
                <Form.Group className="mb-4">
                  <Form.Label>List No.</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="List No."
                    value={inputData.listNo}
                    onChange={handleOnChange("listNo")}
                  />
                </Form.Group>
              </Col>
              <Col xs={8} lg={5}>
                <Form.Group className="mb-4">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="Enter name of the beneficiary"
                    value={inputData.beneficiaryName}
                    onChange={handleOnChange("beneficiaryName")}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} lg={3}>
                <Form.Group className="mb-4">
                  <Form.Label>Fiscal Year</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.fiscalYear}
                    onChange={handleOnChange("fiscalYear")}
                  >
                    <option>Fiscal Year</option>
                    {FISCAL_YEARS.map(({ title, value }) => (
                      <option value={value} key={value}>
                        {title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} lg={2}>
                <Form.Group className="mb-4">
                  <Form.Label>Lot No.</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="Lot No."
                    value={inputData.lot}
                    onChange={handleOnChange("lot")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Phone No.</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="number"
                    placeholder="Enter the phone number of beneficiary"
                    value={inputData.phoneNumber}
                    onChange={handleOnChange("phoneNumber")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Province</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.address.region}
                    onChange={handleOnChange("address-region")}
                  >
                    <option>Select the province</option>
                    {Province.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>District</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.address.district}
                    onChange={handleOnChange("address-district")}
                  >
                    <option>Select district</option>
                    {getFilteredDistricts()?.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={8} lg={8}>
                    <Form.Group className="mb-4">
                      <Form.Label>Municipality</Form.Label>
                      <Form.Select
                        className="select-toggle"
                        value={inputData.address.municipality}
                        onChange={handleOnChange("address-municipality")}
                      >
                        <option>Select municipality</option>
                        {getFilteredMunicipalities()?.map(({ name, id }) => (
                          <option value={name} key={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={4} lg={4}>
                    <Form.Group className="mb-4">
                      <Form.Label>Ward </Form.Label>
                      <Form.Control
                        className="form-design"
                        type="text"
                        placeholder="Ward No."
                        value={inputData.address.ward}
                        onChange={handleOnChange("address-ward")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Select Organization</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={selectedOrganisation}
                    onChange={handleOnChange("organizationId")}
                  >
                    <option value="">Select Organization</option>
                    {orgList?.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Select Sub-Organization</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={selectedSubOrganisation}
                    onChange={handleOnChange("subOrganizationId")}
                  >
                    <option value="">Select Sub-Organization</option>

                    {subOrgList?.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="Enter Latitude"
                    value={inputData.latitude}
                    onChange={handleOnChange("latitude")}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="Enter Longitude"
                    value={inputData.longitude}
                    onChange={handleOnChange("longitude")}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Altitude</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="Enter Altitude"
                    value={inputData.altitude}
                    onChange={handleOnChange("altitude")}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Recommend By</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.recommendedBy}
                    onChange={handleOnChange("recommendedBy")}
                  >
                    <option value="Municipality">Municipality</option>
                    <option value="Ward">Ward</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Citizenship No.</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="text"
                    placeholder="Enter the citizenship no."
                    value={inputData.citizenshipNo}
                    onChange={handleOnChange("citizenshipNo")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={8}>
                <Form.Group className="mb-4">
                  <Form.Label>Irrigation Land Area</Form.Label>
                  <div className="d-flex">
                    <div className="w-50">
                      <div className="form-inline">
                        {inputData.irrigationLandDetails.landType === "Bigha" ? (
                          <>
                            <Form.Control
                              type="number"
                              placeholder="Bigha"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("bigha")[0]}
                              onChange={handleOnChange("irrigationLandDetails-bigha")}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Kattha"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("bigha")[1]}
                              onChange={handleOnChange("irrigationLandDetails-katha")}
                            />
                          </>
                        ) : (
                          <>
                            <Form.Control
                              type="number"
                              placeholder="Ropani"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("ropani")[0]}
                              onChange={handleOnChange("irrigationLandDetails-ropani")}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Anna"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("ropani")[1]}
                              onChange={handleOnChange("irrigationLandDetails-anna")}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="w-50 px-3 inline-radio">
                      <Form.Check
                        inline
                        label="Bigha"
                        name="landArea"
                        type="radio"
                        id={`inline-landArea-1`}
                        checked={inputData.irrigationLandDetails.landType === "Bigha"}
                        onChange={handleOnChange("irrigationLandDetails-landType")}
                        value="Bigha"
                      />
                      <Form.Check
                        inline
                        label="Ropani"
                        name="landArea"
                        type="radio"
                        id={`inline-landArea-2`}
                        checked={inputData.irrigationLandDetails.landType === "Ropani"}
                        onChange={handleOnChange("irrigationLandDetails-landType")}
                        value="Ropani"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label>Files</Form.Label>
                  <div className="d-flex flex-wrap">
                    <Form.Group className="mb-4 me-3">
                      <FilePicker
                        id="citizenshipUrl"
                        onFileSelect={onFileSelect("citizenshipUrl")}
                        placeholder="Upload Citizenship"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4  me-3">
                      <FilePicker
                        id="landOwnershipUrl"
                        onFileSelect={onFileSelect("landOwnershipUrl")}
                        placeholder="Upload Land Ownership Document"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <FilePicker
                        id="profileImageUrl"
                        onFileSelect={onFileSelect("profileImageUrl")}
                        placeholder="Upload Photo"
                      />
                    </Form.Group>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl={5} lg={6} md={12}>
                {!!inputData.citizenshipUrl && (
                  <Row className="folder_Row">
                    <Col xl={4} lg={4} md={4} xs={12} className="folder_shift">
                      <img
                        height="59.45"
                        width="51px"
                        src={DocumentIcon}
                        alt="citizenshipUrl"
                        className="mt-2 d-block mx-auto"
                      />
                    </Col>
                    <Col xl={8} lg={8} md={8} xs={12}>
                      <p className="folder_contents info-folder-contents">
                        {" "}
                        <b>Citizenship</b>
                        <br />
                        ACIM0001256478.pdf
                        <br />
                        0.89 MB
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xl={5} lg={6} md={12}>
                {!!inputData.landOwnershipUrl && (
                  <>
                    <Row className="folder_Row">
                      <Col xl={4} lg={4} md={4} xs={12} className="folder_shift">
                        <img
                          height="59.45"
                          width="51px"
                          src={DocumentIcon}
                          alt="landOwnershipUrl"
                          className="mt-2 d-block mx-auto"
                        />
                      </Col>
                      <Col xl={8} lg={8} md={8} xs={12}>
                        <p className="folder_contents info-folder-contents">
                          {" "}
                          <b>Land Ownership Document</b>
                          <br />
                          ACIM0001256478.pdf
                          <br />
                          0.89 MB
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col xl={5} lg={6} md={12}>
                {!!inputData.profileImageUrl && (
                  <>
                    <Row className="folder_Row">
                      <Col xl={4} lg={4} md={4} xs={12} className="folder_shift folder_shift_first">
                        <img
                          height="59.45"
                          width="51px"
                          src={DocumentIcon}
                          alt="BeneficiaryUrl"
                          className="mt-2 d-block mx-auto"
                        />
                      </Col>
                      <Col xl={8} lg={8} md={8} xs={12}>
                        <p className="folder_contents info-folder-contents">
                          {" "}
                          <b>Profile Photo</b>
                          <br />
                          ACIM0001256478.pdf
                          <br />
                          0.89 MB
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              style={{ borderRadius: "9px", height: "44px", width: "174px" }}
            >
              Save Changes
            </Button>
          </Form>
        </div>

        {uploading && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default EditInfo;
