import { createContext, useReducer, useContext, ReactNode } from "react";

export type Action = {
  type: "TOGGLE_SIDEBAR" | "CLOSE_SIDEBAR" | "SET_HORIZONTAL_FLUID" | "RESET_HORIZONTAL_FLUID";
};
export type State = typeof defaultState;
export type Dispatch = (action: Action) => void;

const defaultState = { isSidebarOpened: false, isHorizontalFluid: false };
const LayoutContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const layoutReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "CLOSE_SIDEBAR":
      return { ...state, isSidebarOpened: false };
    case "SET_HORIZONTAL_FLUID":
      return { ...state, isHorizontalFluid: true };
    case "RESET_HORIZONTAL_FLUID":
      return { ...state, isHorizontalFluid: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(layoutReducer, defaultState);
  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>;
};

const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};

const toggleSidebar = (dispatch: Dispatch) => {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
};

const closeSidebar = (dispatch: Dispatch) => {
  dispatch({
    type: "CLOSE_SIDEBAR",
  });
};

const setHorizontalFluid = (dispatch: Dispatch) => {
  dispatch({
    type: "SET_HORIZONTAL_FLUID",
  });
};

const resetHorizontalFluid = (dispatch: Dispatch) => {
  dispatch({
    type: "RESET_HORIZONTAL_FLUID",
  });
};

export {
  LayoutProvider,
  useLayout,
  toggleSidebar,
  closeSidebar,
  setHorizontalFluid,
  resetHorizontalFluid,
};
