import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiFolderPlus } from "react-icons/fi";
import FilePicker from "../../components/form/FilePicker";
import { LoadingOverlay } from "../../components/shared";
import { getOrganisationList, registerSubOrganization, uploadFile } from "../../../helpers/api";
import { InitialSubOrganizationData } from "./InitialSubOrganizationData";
import { Organisation, SubOrganizationDataType } from "../../ts/types";
import { toast } from "react-toastify";
import DocumentIcon from "../../../assets/icons/documentIcon.png";
import history from "../../../history";
import _ from "lodash";
// import DocumentIcon

const RegisterSubOrganisation = () => {
  const [inputData, setInputData] = useState<SubOrganizationDataType>(InitialSubOrganizationData);
  const [uploading, setUploading] = useState<boolean>(false);
  const [orgList, setOrgList] = useState<Organisation[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState("Select Organization");

  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      // form data
      const bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);

      return uploadFile(bodyFormData).then((res: any) => {
        if (res.status) {
          setInputData((prevData) => ({ ...prevData, [fileName]: res.data.url }));
          toast.success("File uploaded successfully");
        } else {
          toast.error("Error while uploading the file");
        }
      });
    };

    setUploading(true);
    await upload();
    setUploading(false);
  };

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    const value = e.target.value;

    // Deep clone inputData to avoid mutating state directly
    const prevInputData = _.cloneDeep(inputData) as any;

    if (inputKey === "organizationId") {
      // Find the organization ID based on the selected organization name
      const selectedOrg = orgList.find((org) => org.name === value);
      if (selectedOrg) {
        setSelectedOrganisation(selectedOrg?.name);
        prevInputData.organizationId = selectedOrg?.id;
      } else {
        setSelectedOrganisation("Select Organization");
        prevInputData.organizationId = value;
      }
    } else {
      let [levelOneKey, levelTwoKey] = inputKey.split("-") as [keyof SubOrganizationDataType, any];

      if (levelTwoKey) {
        prevInputData[levelOneKey][levelTwoKey] = value;
      } else {
        prevInputData[levelOneKey] = value;
      }
    }

    setInputData(prevInputData);
  };

  const fetchOrgList = useCallback(async () => {
    try {
      const { data } = await getOrganisationList();
      if (Array.isArray(data)) {
        setOrgList(data);
      } else {
        console.error("Expected an array but got:", typeof data);
      }
    } catch (error) {
      console.error("Error fetching organization list:", error);
    }
  }, []);

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);

    registerSubOrganization(inputData)
      .then((res: any) => {
        if (res.status) {
          toast.success("Sub Organization registered successfully");
          history.push("/dashboard");
        } else {
          toast.error("Error while submitting the request.");
        }
      })
      .finally(() => setUploading(false));
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  return (
    <div>
      <Container>
        <h1>Register Sub-Organisation</h1>

        <div>
          <Form className="mt-5" onSubmit={handleOnSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="name"
                  value={inputData.name}
                  onChange={handleOnChange("name")}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Address
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="address"
                  value={inputData.address}
                  onChange={handleOnChange("address")}
                  required
                />
              </Col>
            </Form.Group>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Select Organization</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={selectedOrganisation}
                    onChange={handleOnChange("organizationId")}
                  >
                    <option value="">Select Organization</option>
                    {orgList?.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                First Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="lastName"
                  value={inputData.firstName}
                  onChange={handleOnChange("firstName")}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Last Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="lastName"
                  value={inputData.lastName}
                  onChange={handleOnChange("lastName")}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Email
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="email"
                  className="w-50"
                  name="email"
                  value={inputData.email}
                  onChange={handleOnChange("email")}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Password
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="password"
                  className="w-50"
                  name="password"
                  value={inputData.password}
                  onChange={handleOnChange("password")}
                  required
                />
              </Col>
            </Form.Group>

            <Row>
              <Col xl={4} lg={2} md={4} xs={12}>
                <Form.Group as={Row} controlId="formFile">
                  <FilePicker
                    id="profileImageUrl"
                    onFileSelect={onFileSelect("logoUrl")}
                    placeholder="Upload Photo"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xl={4} lg={4} md={4} xs={12}>
                {!!inputData.logoUrl && (
                  <Row className="folder_Row">
                    <Col xl={4} lg={4} md={4} xs={12} className="folder_shift">
                      <img
                        height="59.45"
                        width="51px"
                        src={DocumentIcon}
                        alt="citizenshipUrl"
                        className="mt-2 d-block mx-auto"
                      />
                    </Col>
                    <Col xl={8} lg={8} md={8} xs={12}>
                      <p className="folder_contents info-folder-contents">
                        {" "}
                        <b>Logo</b>
                        <br />
                        ACIM0001256478.jpg
                        <br />
                        0.89 MB
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "flex-start" }}>
                <div className="d-flex pt-4" style={{ display: "flex", alignItems: "flex-start" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="bg-aepc-dark btn-lg w-min-200"
                    style={{ width: "250px", marginTop: "10px" }}
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
        {uploading && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default RegisterSubOrganisation;
