import React, { FC, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserInfoContext, UserDetailsProps } from "../../context/UserInfoContext";

interface RoutePropsInterface {
  component: FC;
  exact?: boolean;
  path: string;
}

const PrivateRoute: React.FC<RoutePropsInterface> = ({ component, ...rest }) => {
  const { user }: any = useUserInfoContext();

  //TODO: REFACTOR AFTER CONTEXT IMPLEMENTATION
  const isAuthenticated = user ? true : false;
  // const superAdmin = user.role === "SUPERADMIN" ? true : false;

  return (
    <Route
      {...rest}
      render={(props: any) => {
        // check if user is not authenticated
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/sign-in",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }

        return React.createElement(component, props);
      }}
    />
  );
};

const PublicRoute: React.FC<RoutePropsInterface> = ({ component, ...rest }) => {
  //TODO: REFACTOR AFTER CONTEXT IMPLEMENTATION
  const isAuthenticated = false;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

export { PrivateRoute, PublicRoute };
