import { format, getDaysInMonth } from "date-fns";

/* Date Time utils to have proper ticks in the graph */
export const formatDate = (timestamp: any) => {
  return format(parseInt(timestamp), "hh:mm a");
};

export const months = (year?: number) => {
  return [
    {
      name: "January",
      short: "Jan",
      number: 1,
      days: 31,
    },
    {
      name: "February",
      short: "Feb",
      number: 2,
      days: year && year % 4 == 0 ? 29 : 28, // LOGIC FOR LEAP YEAR
    },
    {
      name: "March",
      short: "Mar",
      number: 3,
      days: 31,
    },
    {
      name: "April",
      short: "Apr",
      number: 4,
      days: 30,
    },
    {
      name: "May",
      short: "May",
      number: 5,
      days: 31,
    },
    {
      name: "June",
      short: "Jun",
      number: 6,
      days: 30,
    },
    {
      name: "July",
      short: "Jul",
      number: 7,
      days: 31,
    },
    {
      name: "August",
      short: "Aug",
      number: 8,
      days: 31,
    },
    {
      name: "September",
      short: "Sep",
      number: 9,
      days: 30,
    },
    {
      name: "October",
      short: "Oct",
      number: 10,
      days: 31,
    },
    {
      name: "November",
      short: "Nov",
      number: 11,
      days: 30,
    },
    {
      name: "December",
      short: "Dec",
      number: 12,
      days: 31,
    },
  ];
};

export const shortMonths = months().map((each) => each.short);

export const makeDayListForMonth = (month: number) => {
  const totalDays = getDaysInMonth(new Date(`2000-${month}-01`));
  const newArray = [];

  for (let i = 1; i <= totalDays; i += 1) {
    newArray.push(i);
  }

  return newArray;
};

export const getCurrentGmtTime = () => {
  return new Date().getTime() + 20700000;
};

export const formatTimeDuration = (duration: number) => {
  if (!duration) {
    return "N/A";
  }
  //received in milliseconds
  let seconds = duration / 1000;
  if (seconds < 60) {
    return `${seconds.toFixed(0)} seconds`;
  }
  let minutes = seconds / 60;
  if (minutes < 60) {
    seconds = seconds % 60;
    return `${minutes.toFixed(0)}m  ${seconds.toFixed(0)}s`;
  }
  let hours = minutes / 60;
  if (seconds < 60) {
    minutes = minutes % 60;
    return `${hours.toFixed(0)}h ${minutes.toFixed(0)}m`;
  }
  let days = hours / 24;
  hours = hours % 24;

  return `${days.toFixed(0)}d ${hours.toFixed(0)}h`;
};
