import React, { ChangeEvent, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../history";
// Helpers
import {
  createMeterFilter,
  fetchMeterDetailByProjectId,
  getMeterById,
  getMeterFilterById,
  updateMeterFilterData,
} from "../../../helpers/api";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../components/shared";
// Types
import { EnvironmentalParams, SystemParams } from "../../ts/enums";

interface FiltersWithMeterId extends Record<string, string> {
  meterId: string;
}

const ConfigureMeter: React.FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [swpsStatus, setSwpsStatus] = useState("");
  const [meterId, setMeterId] = useState("");
  const [filterId, setFilterId] = useState("");
  const [entryExists, setEntryExists] = useState(true);
  const { projectId } = useParams<{ projectId: string }>();

  const filterBySystemParamsOptions = {
    system: [
      { name: "Solar Current", value: SystemParams.SOLAR_CURRENT },
      { name: "Solar Voltage", value: SystemParams.SOLAR_VOLTAGE },
      { name: "Head", value: SystemParams.HEAD },
      { name: "Power", value: SystemParams.POWER },
      { name: "RunTime", value: SystemParams.RUNTIME },
      { name: "Ac Voltage", value: SystemParams.AC_VOLTAGE },
      { name: "Ac Current", value: SystemParams.AC_CURRENT },
      { name: "Flow", value: SystemParams.FLOW },
      { name: "Discharge", value: SystemParams.DISCHARGE },
    ],
    environmental: [
      { name: "Temperature", value: EnvironmentalParams.TEMPERATURE },
      { name: "Humidity", value: EnvironmentalParams.HUMIDITY },
      { name: "EC Sensor Temperature", value: EnvironmentalParams.EC_SENSOR_TEMPERATURE },
      { name: "EC Sensor Humidity", value: EnvironmentalParams.EC_SENSOR_HUMIDITY },
      { name: "EC Sensor Moisture", value: EnvironmentalParams.EC_SENSOR_MOISTURE },
      { name: "EC Conductivity", value: EnvironmentalParams.EC_CONDUCTIVITY },
    ],
  };

  const [filters, setFilters] = useState<Record<string, boolean>>({
    [SystemParams.SOLAR_CURRENT]: true,
    [SystemParams.SOLAR_VOLTAGE]: true,
    [SystemParams.HEAD]: true,
    [SystemParams.POWER]: true,
    [SystemParams.RUNTIME]: true,
    [SystemParams.FLOW]: true,
    [SystemParams.AC_CURRENT]: true,
    [SystemParams.AC_VOLTAGE]: true,
    [SystemParams.DISCHARGE]: true,
    [EnvironmentalParams.TEMPERATURE]: true,
    [EnvironmentalParams.HUMIDITY]: true,
    [EnvironmentalParams.EC_SENSOR_TEMPERATURE]: true,
    [EnvironmentalParams.EC_SENSOR_HUMIDITY]: true,
    [EnvironmentalParams.EC_SENSOR_MOISTURE]: true,
    [EnvironmentalParams.EC_CONDUCTIVITY]: true,
  });

  useEffect(() => {
    fetchMeterDetailByProjectId(projectId)
      .then((res: any) => {
        if (res.status) {
          return res.data.projectMeter.id;
        }
      })
      //   mId --> meterId
      .then((mId) => {
        setMeterId(mId);
        getMeterById(mId).then((res: any) => {
          if (res.status) {
            setSwpsStatus(res.data.SWPS);

            getMeterFilterById(mId).then((meterRes: any) => {
              if (meterRes.data) {
                setEntryExists(true);
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  ...meterRes.data,
                }));
              } else {
                setEntryExists(false);
              }
            });
          }
        });
      });
  }, []);

  const handleCheckboxChange = (value: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [value]: e.target.checked,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    let response;

    let payload: FiltersWithMeterId;

    try {
      if (!entryExists) {
        payload = {
          ...filters,
          meterId,
        };
        response = await createMeterFilter(payload);
      } else {
        payload = {
          ...filters,
          meterId: meterId,
        };

        delete payload.createdAt;
        delete payload.updatedAt;
        response = await updateMeterFilterData(payload);
      }

      if (response?.data) {
        toast.success("Configurtations updated!");
        history.push(`/meter-details/${projectId}`);
      }
    } catch (err) {
      toast.error("Failed to save configurtations!");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="page-header">
        <div className="d-flex p-3">
          <GoBack />
          <div className="flex-grow-1 text-center">
            <PageTitle title="Configure Meter" />
          </div>
        </div>
        <Divider type="dark" />
      </div>

      <div className="register-project m-5">
        <Form onSubmit={handleOnSubmit}>
          {swpsStatus === "Y" && (
            <div className="mb-4">
              <h5 className="mb-3">System Parameters</h5>
              {filterBySystemParamsOptions.system.map((option) => (
                <Form.Group key={option.value} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={option.value}
                    label={<span style={{ cursor: "pointer" }}>{option.name}</span>}
                    checked={filters[option.value] || false}
                    onChange={handleCheckboxChange(option.value)}
                  />
                </Form.Group>
              ))}
            </div>
          )}

          <div>
            <h5 className="mb-3">Environmental Parameters</h5>
            {filterBySystemParamsOptions.environmental.map((option) => (
              <Form.Group key={option.value} className="mb-3">
                <Form.Check
                  type="checkbox"
                  id={option.value}
                  label={<span style={{ cursor: "pointer" }}>{option.name}</span>}
                  checked={filters[option.value] || false}
                  onChange={handleCheckboxChange(option.value)}
                  style={{ cursor: "pointer" }}
                />
              </Form.Group>
            ))}
          </div>

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </div>

      {submitting && <LoadingOverlay />}
    </div>
  );
};

export default ConfigureMeter;
