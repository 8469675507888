import React, { ChangeEvent, useState, useEffect } from "react";
import { Row, Col, Form, Button, ProgressBar, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../../history";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../../components/shared";
import styles from "../../../components/form/Switch.module.scss";
// Types
import { ProjectDataType, MountingStructureDetailsType } from "../../../ts/types";
// Helpers
import { getFarmerById, updateProject } from "../../../../helpers/api";
// Data
import { InitialProjectData } from "../InitialProjectData";

const Structure: React.FC = () => {
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    getFarmerById(projectId).then((res: any) => {
      if (res.status) {
        setInputData(res.data);
      }
    });
  }, [projectId]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [
      keyof ProjectDataType,
      keyof MountingStructureDetailsType
    ];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = { ...inputData, status: "INSTALLED" };
    updateProject(formData).then((res: any) => {
      if (res.status) {
        toast.success("Project updated successfully");
        setSubmitting(false);
        history.push(`/project-status/${projectId}/operational`);
      } else {
        toast.error("Error while updating the project");
      }
    });
  };

  return (
    <div>
      <Container className="form_container">
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Project Details" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>

        <div className="register-project m-l-5 mb-5 form_cover">
          <Form onSubmit={handleOnSubmit}>
            <div className="progress-container mb-5">
              <h4 className="mb-3">Structure</h4>
              <Row>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
              </Row>
            </div>

            <h4 className="text-grey mb-4">Mounting Structure</h4>
            <Row className="mb-5">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Mounting Structure Material </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder=" Manufacturer material"
                    value={inputData.mountingStructureDetails.material}
                    onChange={handleOnChange("mountingStructureDetails-material")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Ground Clearance From Bottom Edge </Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder=" Ground Clearance"
                    value={inputData.mountingStructureDetails.groundClearance}
                    onChange={handleOnChange("mountingStructureDetails-groundClearance")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label> Non-Corrosive Fastners </Form.Label>
                  <div className="d-block">
                    <input
                      type="radio"
                      name="property_typesi_id"
                      className={styles.customRadio}
                      id="custom-radiosi-1"
                      checked={inputData.mountingStructureDetails.nonCorrosiveFastner === "Yes"}
                      onChange={handleOnChange("mountingStructureDetails-nonCorrosiveFastner")}
                      value="Yes"
                    />
                    <label htmlFor="custom-radiosi-1" style={{ fontSize: "1rem" }}>
                      Yes
                    </label>
                    <input
                      type="radio"
                      name="property_typesi_id"
                      className={styles.customRadio}
                      id="custom-radiosi-2"
                      checked={inputData.mountingStructureDetails.nonCorrosiveFastner === "No"}
                      onChange={handleOnChange("mountingStructureDetails-nonCorrosiveFastner")}
                      value="No"
                    />
                    <label htmlFor="custom-radiosi-2" style={{ fontSize: "1rem" }}>
                      No
                    </label>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className="form-btn-group">
              <Button variant="primary" type="submit">
                Save & Continue
              </Button>
              &ensp;
              <Button
                variant="outline-primary"
                onClick={() => history.push(`/project-status/${projectId}/installation`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>

        {submitting && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default Structure;
