import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";

//styles
import styles from "./FilePicker.module.scss";

// Assests
import UploadIcon from "../../../assets/icons/documentIcon.png";

interface Props {
  placeholder?: string;
  onFileSelect: (file: File) => void;
  id: string;
}

const FilePicker: React.FC<Props> = ({ id, placeholder, onFileSelect }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onFileSelect(e.target.files?.[0]);
    }
  };

  return (
    <div className={styles.filePicker}>
      <label className={styles.filePickerLabel} htmlFor={`custom-file-upload-${id}`}>
        <img src={UploadIcon} className="uploadIcon" />

        {placeholder || "Upload File"}
      </label>
      <input type="file" className="d-none" id={`custom-file-upload-${id}`} onChange={onChange} />
    </div>
  );
};

export default FilePicker;
