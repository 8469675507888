export enum DummyEnum {
  pos = "Positive",
  neg = "Negative",
}

export enum FilterByFrequency {
  DAILY = "daily",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}
export enum SystemParams {
  HEAD = "head",
  POWER = "power",
  RUNTIME = "runTime",
  DISCHARGE = "discharge",
  AC_CURRENT = "acCurrent",
  SOLAR_VOLTAGE = "solarVoltage",
  SOLAR_CURRENT = "solarCurrent",
  AC_VOLTAGE = "acVoltage",
  FLOW = "flowInLt",
}
export enum EnvironmentalParams {
  HUMIDITY = "humidity",
  TEMPERATURE = "temperature",
  EC_SENSOR_TEMPERATURE = "ECSensorTemperature",
  EC_SENSOR_MOISTURE = "ECSensorMoisture",
  EC_CONDUCTIVITY = "ECConductivity",
  EC_SENSOR_HUMIDITY = "ECSensorHumidity",
}

export enum ChartTypes {
  LINEAR_AREA = "LINEAR_AREA",
  BAR = "BAR",
}

// LAND AREA UNITS
export enum LandAreaUnits {
  BIGHA = "bigha",
  ROPANI = "ropani",
}

export enum ProjectStates {
  REGISTERED = "REGISTERED",
  INSTALLED = "INSTALLED",
  INITIALIZD = "INITIALIZED",
  APPROVED = "APPROVED",
  DISPATCHED = "DISPATCHED",
  OPERATIONAL = "OPERATIONAL",
}

export enum Currency {
  DEFAULT = "Rs.",
}
