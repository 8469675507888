import { OrganizationDataType } from "../../ts/types";

export const InitialOrganizationData: OrganizationDataType = {
  name: "",
  address: "",
  logoUrl: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};
