import React, { useState } from "react";
import { Card, Button, Form, Container, Toast } from "react-bootstrap";

//Assets
import Logo from "../../../assets/img/logo.svg";

//History
import history from "../../../history";

//Context
import { useUserInfoContext } from "../../context/UserInfoContext";

//Helpers
import { login } from "../../../helpers/api";
import CustomModal from "../../components/shared/CustomModal";

const SignIn: React.FC = () => {
  const { setUser } = useUserInfoContext() as any;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showForgotPassAlert, setShowForgotPassAlert] = useState(false);

  const onEmailHandler = (e: any) => {
    setEmail(e.currentTarget.value);
  };

  const onPasswordInputHandler = (e: any) => {
    setPassword(e.currentTarget.value);
  };

  const [show, setShow] = useState(false);

  const formSubmitHandler = (e: any) => {
    e.preventDefault();

    login(email, password).then((res: any) => {
      if (res.status) {
        if (res.data.token) {
          localStorage.setItem("user", JSON.stringify(res.data));
          setUser(JSON.stringify(res.data));
          history.push("/dashboard");
        } else {
          setShow(true);
        }
      }
    });
  };

  return (
    <div className="sign-in d-flex align-items-center bg-aepc vh-100">
      <Container>
        <Card className="p-3 p-lg-5 m-auto w-max-500 br-60 signIn-card">
          <Form onSubmit={formSubmitHandler}>
            <div className="d-flex justify-content-center mt-3 mb-5">
              <img src={Logo} alt="AEPC Logo" className="logo" />
            </div>
            <Form.Group className="mb-4">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter your email address"
                className="input-bg-grey border-0 py-2"
                onChange={onEmailHandler}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter your password"
                className="input-bg-grey border-0 py-2"
                onChange={onPasswordInputHandler}
              />
            </Form.Group>

            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={50000}
              animation={true}
              autohide
            >
              <Toast.Body style={{ backgroundColor: "pink", color: "red" }}>
                The email or password does not match our records. Please try again.
              </Toast.Body>
            </Toast>

            <div className="d-flex flex-column align-items-center pt-4">
              <Button variant="primary" type="submit" className="bg-aepc-dark btn-lg w-min-200 ">
                Login
              </Button>

              <small
                className="pressable text-aepc pt-3 fw-500"
                onClick={() => setShowForgotPassAlert(true)}
              >
                Forgot your password ?
              </small>
            </div>
          </Form>
        </Card>
      </Container>

      {showForgotPassAlert && (
        <CustomModal hideAlert={() => setShowForgotPassAlert(false)}>
          <div>
            <h4
              className="mb-3 text-center"
              style={{ textTransform: "uppercase", fontSize: "20px" }}
            >
              OOPS YOU FORGET YOUR PASSWORD
            </h4>
            <p className="text-center mb-0">
              No worries, please contact <strong>+977 9802300745</strong> and recover your password!{" "}
              <span style={{ display: "block", marginTop: "10px" }}>Thank you!!!</span>
            </p>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default SignIn;
