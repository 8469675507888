import Province from "./provinces.json";
import District from "./districts.json";
import Municipality from "./municipalities.json";

const getDistrictByProvince = (province: string) => {
  if (!!province) {
    const regionData = Province.find((each) => each.name === province);

    return District.filter((each) => each.province_id === regionData?.id);
  }
  return [];
};

const getMunicipalitiesByDistrict = (district: string) => {
  if (!!district) {
    const districtData = District.find((each) => each.name === district);
    return Municipality.filter((each) => each.district_id === districtData?.id);
  }

  return [];
};

export { Province, District, Municipality, getDistrictByProvince, getMunicipalitiesByDistrict }; 
