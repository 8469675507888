const NepaliDate = require("nepali-date");
const date = new NepaliDate();

const startYear = 2075;
const endYear = date.year;

export const FISCAL_YEARS: { title: string; value: string }[] = [];

for (let year = startYear; year < endYear; year++) {
  const fiscalYear = {
    title: `${year}/${year + 1}`,
    value: `${year}/${year + 1}`,
  };
  FISCAL_YEARS.push(fiscalYear);
}
