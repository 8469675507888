import React from "react";

interface PropsInterface {
  children: JSX.Element;
}

const ContentWrapper: React.FC<PropsInterface> = ({ children }) => {
  return <div className="main-content">{children}</div>;
};

export default ContentWrapper;
