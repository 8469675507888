export const getQueryParam = (prop: string) => {
  const params: any = {};
  const search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  const definitions = search.split("&");
  definitions.forEach(function (val, key) {
    const parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : undefined;
};

export const isMobile = (): boolean => {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
};

export const isMdScreen = (): boolean => {
  if (window) {
    return window.matchMedia(`(max-width: 992px)`).matches;
  }

  
  return false;
};


export const isLgScreen = (): boolean => {
  if (window) {
    return window.matchMedia(`(max-width: 1200px)`).matches;
  }

  
  return false;
};
