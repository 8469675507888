import React, { ChangeEvent, useState, useEffect } from "react";
import { Row, Col, Form, Button, ProgressBar, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../../history";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../../components/shared";
import styles from "../../../components/form/Switch.module.scss";
// Types
import {
  ProjectDataType,
  CombinerBoxDetailsType,
  CircuitBreakerDetailsType,
  SpdDetailsType,
  CableDetailsType,
  ControllerDetailsType,
} from "../../../ts/types";
// Helpers
import { getFarmerById, updateProject } from "../../../../helpers/api";
// Data
import { InitialProjectData } from "../InitialProjectData";

const TechinicalSpecification3: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getFarmerById(projectId).then((res: any) => {
      if (res.status) {
        setInputData(res.data);
      }
    });
  }, [projectId]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [
      keyof ProjectDataType,
      (
        | keyof CombinerBoxDetailsType
        | keyof CircuitBreakerDetailsType
        | keyof SpdDetailsType
        | keyof CableDetailsType
        | keyof ControllerDetailsType
      )
    ];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    updateProject(inputData)
      .then((res: any) => {
        if (res.status) {
          toast.success("Project updated successfully");
          setSubmitting(false);
          history.push(`/edit-project/${projectId}/technical-specification-4`);
        } else {
          toast.error("Error while updating the project");
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Container className="form_container">
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Project Details" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>

        <div className="register-project m-l-5 my-5 form_cover">
          <Form onSubmit={handleOnSubmit}>
            <div className="progress-container mb-5">
              <h4 className="mb-3">Technical Specification</h4>
              <Row>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={75} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={0} className="custom-progress-bar" />
                </Col>
              </Row>
            </div>

            <h4 className="text-grey mb-2">Combiner Box</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Manufacturer Name </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder=" Manufacturer Name"
                    value={inputData.combinerBoxDetails.manufacturerName}
                    onChange={handleOnChange("combinerBoxDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="text-grey mb-2">Circuit Breaker</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Manufacturer Name </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder=" Manufacturer Name"
                    value={inputData.circuitBreakerDetails.manufacturerName}
                    onChange={handleOnChange("circuitBreakerDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} lg={3}>
                <Form.Group className="mb-4">
                  <Form.Label> Voltage Rating </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Volatage Rating"
                    value={inputData.circuitBreakerDetails.voltageRating}
                    onChange={handleOnChange("circuitBreakerDetails-voltageRating")}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} lg={3}>
                <Form.Group className="mb-4">
                  <Form.Label> Current Rating </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Current Rating"
                    value={inputData.circuitBreakerDetails.currentRating}
                    onChange={handleOnChange("circuitBreakerDetails-currentRating")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="text-grey mb-2">Surge Protection Device (SPD)</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Manufacturer Name </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Manufacturer Name"
                    value={inputData.spdDetails.manufacturerName}
                    onChange={handleOnChange("spdDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Brand/ Model</Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Enter brand model"
                    value={inputData.spdDetails.brand}
                    onChange={handleOnChange("spdDetails-brand")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>SPD type</Form.Label>

                  <Form.Select
                    className="select-toggle"
                    value={inputData.spdDetails.spdType}
                    onChange={handleOnChange("spdDetails-spdType")}
                  >
                    <option>Select SPD type</option>

                    <option value="Type 1">Type 1</option>
                    <option value="Type 2">Type 2</option>
                    <option value="Type 3">Type 3</option>
                    <option value="Type 4">Type 4</option>
                    <option value="Type 5">Type 5</option>
                    <option value="Type 6">Type 6</option>
                    <option value="Type 7">Type 7</option>
                    <option value="Type 8">Type 8</option>
                    <option value="Type 9">Type 9</option>
                    <option value="Type 10">Type 10</option>
                    <option value="Type 11">Type 11</option>
                    <option value="Type 12">Type 12</option>
                    <option value="Type 13">Type 13</option>
                    <option value="Type 14">Type 14</option>
                    <option value="Type 15">Type 15</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Maximum Continuos Operating Voltage</Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Maximum Continuos Operating Voltage"
                    value={inputData.spdDetails.operatingVoltage}
                    onChange={handleOnChange("spdDetails-operatingVoltage")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h4 className="text-grey mb-2">Cable</h4>
            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Manufacturer Name </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Manufacturer Name"
                    value={inputData.cableDetails.manufacturerName}
                    onChange={handleOnChange("cableDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Brand/ Model</Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Enter brand model"
                    value={inputData.cableDetails.brand}
                    onChange={handleOnChange("cableDetails-brand")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Conductor Material</Form.Label>
                  {/* <Form.Control
                  required
                  type="text"
                  placeholder="SPD type"
                  value={inputData.cableDetails.conductorMaterial}
                  onChange={handleOnChange("cableDetails-conductorMaterial")}
                /> */}
                  <Form.Select
                    className="select-toggle"
                    value={inputData.cableDetails.conductorMaterial}
                    onChange={handleOnChange("cableDetails-conductorMaterial")}
                  >
                    <option>Select Conductor Material</option>
                    <option
                      value="Copper
"
                    >
                      Copper
                    </option>
                    <option value="Aluminium">Aluminium</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={6} lg={3}>
                <Form.Label>Cable</Form.Label>

                <div>
                  <input
                    type="radio"
                    name="property_typesi_id"
                    className={styles.customRadio}
                    id="custom-radiosi-1"
                    checked={inputData.cableDetails.cable === "DC"}
                    onChange={handleOnChange("cableDetails-cable")}
                    value="DC"
                  />
                  <label htmlFor="custom-radiosi-1" style={{ fontSize: "1rem" }}>
                    DC
                  </label>
                  <input
                    type="radio"
                    name="property_typesi_id"
                    className={styles.customRadio}
                    id="custom-radiosi-2"
                    checked={inputData.cableDetails.cable === "AC"}
                    onChange={handleOnChange("cableDetails-cable")}
                    value="AC"
                  />
                  <label htmlFor="custom-radiosi-2" style={{ fontSize: "1rem" }}>
                    AC
                  </label>
                </div>
              </Col>

              <Col xs={6} lg={3}>
                <Form.Label>UV Ressistance</Form.Label>
                <div>
                  <input
                    type="radio"
                    name="property_types_id"
                    className={styles.customRadio}
                    id="custom-radios-1"
                    checked={inputData.cableDetails.uvResistance === "Yes"}
                    onChange={handleOnChange("cableDetails-uvResistance")}
                    value="Yes"
                  />
                  <label htmlFor="custom-radios-1" style={{ fontSize: "1rem" }}>
                    Yes
                  </label>
                  <input
                    type="radio"
                    name="property_types_id"
                    className={styles.customRadio}
                    id="custom-radios-2"
                    checked={inputData.cableDetails.uvResistance === "No"}
                    onChange={handleOnChange("cableDetails-uvResistance")}
                    value="No"
                  />
                  <label htmlFor="custom-radios-2" style={{ fontSize: "1rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>

            <div className="form-btn-group">
              <Button variant="primary" type="submit">
                Save & Continue
              </Button>
              &ensp;
              <Button
                variant="outline-primary"
                onClick={() => history.push(`/project-status/${projectId}/installation`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>

        {submitting && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default TechinicalSpecification3;
