export default class Response {
  public status: boolean;

  public data: any;

  public messages: string;

  public exception: string;

  constructor(status: boolean, data: any, message: string, exception: string) {
    this.status = status;
    this.data = data;
    this.messages = message;
    this.exception = exception;
  }
}
