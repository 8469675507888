import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import history from "../../../history";
import { Link, useParams } from "react-router-dom";
import { getBeneficairyDetails, removeBeneficiary } from "../../../helpers/api";
import { MdDelete } from "react-icons/md";
import { RemoveBeneficiaryDataType } from "../../ts/types";
import { toast } from "react-toastify";
import { LoadingOverlay } from "../../components/shared";
import { useUserInfoContext } from "../../context/UserInfoContext";
import EditIcon from "../../../assets/icons/editIcon.png";

interface Beneficiary {
  id: "";
  name: "";
  phoneNumber: "";
  landArea: "";
  landAreaUnit: "";
  crops: [];
}
const initialRemoveBeneficiary: RemoveBeneficiaryDataType = {
  id: "",
  projectId: "",
};

const ViewBeneficiary = () => {
  const { projectId } = useParams() as any;
  const [beneficiary, setBeneficiary] = useState<Beneficiary[]>([]);
  const [uploading, setUploading] = useState(false);
  const { user }: any = useUserInfoContext();
  const userRole = user.role;
  // to show the confirm remove modal
  const [show, setShow] = useState(false);
  const [inputData, setInputData] = useState<RemoveBeneficiaryDataType>(initialRemoveBeneficiary);

  const handleModalToggle = () => {
    setShow(!show);
  };

  const handleOnClick = (id: string) => {
    updateId(id);
    handleModalToggle();
  };
  const handleOnRemove = () => {
    setUploading(true);
    setInputData((inputData.projectId = projectId));
    if (!inputData.projectId || !inputData.id) {
      toast.error("Failed to remove beneficiary");
      setUploading(false);
    } else {
      removeBeneficiary(inputData)
        .then((res: any) => {
          if (res.status) {
            toast.success("Successfully removed beneficiary");
            window.location.reload();
            handleModalToggle();
          } else {
            toast.error("Error removing beneficiary");
          }
        })
        .finally(() => setUploading(false));
    }
  };

  const updateId = (newId: string) => {
    setInputData((prevData) => ({
      ...prevData,
      id: newId,
    }));
  };

  useEffect(() => {
    getBeneficairyDetails(projectId).then((res: any) => {
      setBeneficiary(res.data);
    });
  }, [projectId]);

  return (
    <div>
      <Container fluid>
        <Row
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            marginBottom: "30px",
          }}
        >
          <Col xl={10} lg={8} md={6} xs={6} className="pt-2 page-title">
            <h2>Beneficiary Details</h2>
          </Col>
          <Col
            xl={2}
            lg={4}
            md={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Link to={`/add-beneficiary/${projectId}`}>
              <Button
                variant="outline-primary"
                style={{
                  fontSize: "0.8rem",
                  borderRadius: "8px",
                  border: "3px solid",
                  minWidth: "150px",
                }}
              >
                <b>Add Beneficiary </b>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>

      {beneficiary.length > 0 ? (
        <Container
          fluid
          style={{
            border: "1.5px solid silver",
            borderRadius: "5px",
            padding: "0.8rem 1.3rem",
            fontWeight: "normal",
          }}
        >
          <Table>
            <thead className="farmerDetail__thead">
              <tr
                style={{
                  borderBottom: "1.5px solid silver",
                  fontWeight: "normal",
                }}
              >
                <th
                  style={{
                    textAlign: "left",
                    borderRight: "1px solid silver",
                    maxWidth: "200px",
                    width: "180px",
                  }}
                >
                  Phone Number
                </th>

                <th style={{ textAlign: "center", borderRight: "1px solid silver" }}>Name</th>

                <th style={{ textAlign: "center", borderRight: "1px solid silver" }}>Land Area</th>
                <th style={{ textAlign: "center", borderRight: "1px solid silver" }}>Unit</th>

                <th
                  style={{
                    textAlign: "center",
                    borderRight: userRole === "SUPERADMIN" ? "1px solid silver" : "none",
                  }}
                >
                  Type of Crops
                </th>

                {userRole === "SUPERADMIN" && (
                  <th style={{ textAlign: "center", minWidth: "50px" }}>Actions </th>
                )}
              </tr>
            </thead>
            <tbody>
              {beneficiary.map((bene, index) => (
                <>
                  <tr
                    style={{
                      marginTop: 10,
                      fontWeight: "normal",
                    }}
                    key={index}
                  >
                    <td
                      style={{
                        padding: "1.5rem 0.5rem",
                        border: "1px solid silver",
                        textAlign: "left",
                        borderLeft: "none",
                        borderBottom: index < beneficiary.length - 1 ? "1px solid silver" : "none",
                      }}
                    >
                      {bene.phoneNumber ? bene.phoneNumber : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "1.5rem 0.5rem",
                        border: "1px solid silver",
                        textAlign: "center",
                        borderBottom: index < beneficiary.length - 1 ? "1px solid silver" : "none",
                      }}
                    >
                      {bene.name ? bene.name : "N/A"}
                    </td>

                    <td
                      style={{
                        padding: "1.5rem 0.5rem",
                        border: "1px solid silver",
                        textAlign: "center",
                        borderBottom: index < beneficiary.length - 1 ? "1px solid silver" : "none",
                      }}
                    >
                      {bene.landArea ? bene.landArea : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "1.5rem 0.5rem",
                        border: "1px solid silver",
                        textAlign: "center",
                        borderBottom: index < beneficiary.length - 1 ? "1px solid silver" : "none",
                      }}
                    >
                      {bene.landAreaUnit ? bene.landAreaUnit : "N/A"}
                    </td>
                    <td
                      style={{
                        padding: "1.5rem 0.5rem",
                        border: "1px solid silver",
                        textAlign: "center",
                        borderBottom: index < beneficiary.length - 1 ? "1px solid silver" : "none",
                        borderRight: "none",
                      }}
                    >
                      {bene.crops ? bene.crops.map((crop, i) => <p key={i}>{crop}</p>) : "N/A"}
                    </td>

                    {userRole === "SUPERADMIN" && (
                      <td
                        style={{
                          padding: "1.5rem 0.5rem",
                          border: "1px solid silver",
                          textAlign: "center",
                          borderBottom:
                            index < beneficiary.length - 1 ? "1px solid silver" : "none",
                          borderRight: "none",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100px",
                            gap: "20px",
                            cursor: "pointer",
                            // border: "1px solid red",
                          }}
                          onClick={() => handleOnClick(bene.id)}
                        >
                          <Link to={`/edit-beneficiary/${bene.id}`}>
                            <img src={EditIcon} alt="Edit" className="generalInfo_Icons" />
                          </Link>
                          <MdDelete style={{ color: "#B51F1F", fontSize: 30 }} />
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Container>
      ) : (
        <Container fluid>
          <div style={{ display: "flex" }}>
            <p>No beneficiaries yet, add to see the updated list</p>
          </div>
        </Container>
      )}

      <Modal show={show} onHide={handleModalToggle} centered>
        <Modal.Body
          style={{
            padding: "0 2rem 2rem 3rem",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <div style={{ marginTop: "3rem" }}>
            Are you sure you want to delete this user? This action cannot be undone
          </div>
          <div
            style={{ marginTop: "20px", display: "flex", justifyContent: "flex-start", gap: 10 }}
          >
            <Button
              variant="primary"
              style={{
                fontSize: "0.9rem",
                marginRight: "5px",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleOnRemove()}
            >
              <b>Confirm</b>
            </Button>
            <Button
              variant="secondary"
              style={{
                fontSize: "0.9rem",
                marginRight: "5px",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
              }}
              onClick={() => handleModalToggle()}
            >
              <b>cancel</b>
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {uploading && <LoadingOverlay />}
    </div>
  );
};

export default ViewBeneficiary;
