import { useEffect, useState } from "react";
import { Col, Button, Row, Nav, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { ChevronLeft } from "react-bootstrap-icons";

// History
import history from "../../../history";
// Components
import { Divider, GoBack, PageTitle } from "../../components/shared";

//Types
import { projectDetailsProps } from "./ts/types";

//Helpers
import { getProjectDetails } from "../../../helpers/api";

//Enums
import { LandAreaUnits } from "../../ts/enums";
import { useUserInfoContext } from "../../context/UserInfoContext";

const ProjectDetails = () => {
  const { projectId } = useParams() as any;
  const [projectDetails, setProjectDetails] = useState({} as projectDetailsProps);
  const [loading, setLoading] = useState(true);
  const [gobackBtn, setgobackBtn] = useState(false);

  const { user }: any = useUserInfoContext();

  useEffect(() => {
    //fetch Project details and store it in state

    getProjectDetails(projectId)
      .then((res) => {
        if (!res.data?.id) {
          throw new Error("Something went wrong while trying to get Project details");
        }
        setProjectDetails(res.data as projectDetailsProps);
      })

      .finally(() => setLoading(false));
  }, [projectId]);

  const generalInformation = [
    [
      { name: "List No.", value: projectDetails?.listNo },
      { name: "Fiscal Year", value: projectDetails?.fiscalYear },
      { name: "Lot", value: projectDetails?.lot },
      { name: "Company Name", value: projectDetails?.companyName },
    ],
    [
      { name: "Name", value: projectDetails?.beneficiaryName },
      { name: "Citizenship No.", value: projectDetails?.citizenshipNo },
      { name: "Contact Number 1", value: projectDetails?.phoneNumber },
      { name: "Contact Number 2", value: projectDetails?.secondaryContactNumber },
    ],
    [
      { name: "Province", value: projectDetails?.address?.region },
      { name: "District", value: projectDetails?.address?.district },
      { name: "Municipality", value: projectDetails?.address?.municipality },
      { name: "Ward", value: projectDetails?.address?.ward },
      { name: "Tole", value: projectDetails?.address?.tole },
      { name: "Nearest Highway", value: projectDetails?.address?.nearestHighway },
      {
        name: "Distance from nearest highway",
        value: projectDetails?.address?.distanceFromNearestHighway,
      },
      { name: "Recommended By", value: projectDetails?.recommendedBy },
    ],
    [
      { name: "Total Cost", value: projectDetails?.totalCost },
      { name: "Total Subsity", value: projectDetails?.totalSubsidy },
      { name: "User Contribution", value: projectDetails?.userContribution },
      {
        name: "Other Contributions",
        value: [
          projectDetails?.otherContributions?.contribution1,
          projectDetails?.otherContributions?.contribution2,
        ],
      },
    ],
    [
      { name: "Water Source", value: projectDetails?.waterSourceDetails?.source },
      { name: "Total head", value: projectDetails?.waterSourceDetails?.head },
      { name: "Water Discharge", value: projectDetails?.waterSourceDetails?.discharge },
      {
        name: "Irrigation Land Area",
        value: (() => {
          const area = projectDetails?.irrigationLandDetails?.land?.split("-");
          if (
            projectDetails?.irrigationLandDetails?.landType?.toLowerCase() === LandAreaUnits.BIGHA
          ) {
            return `${area[0]} Bigha  ${area[1]} Kattha`;
          }
          if (
            projectDetails?.irrigationLandDetails?.landType?.toLowerCase() === LandAreaUnits.ROPANI
          ) {
            return `${area[0]} Ropani ${area[1]} Aana`;
          }
          return null;
        })(),
      },
    ],
  ];

  const technicalSpecifications = [
    {
      subheading: "Solar Array",
      data: [
        { name: "Type", value: projectDetails?.solarArrayDetails?.type },
        { name: "Manufacturer Name", value: projectDetails?.solarArrayDetails?.manufacturerName },
        { name: "Brand/Model", value: projectDetails?.solarArrayDetails?.brand },
        {
          name: "Individual Module Capacity (in Wp)",
          value: projectDetails?.solarArrayDetails?.moduleCapacity,
        },
        { name: "Total Modules", value: projectDetails?.solarArrayDetails?.totalModules },
        { name: "Total Capacity", value: projectDetails?.solarArrayDetails?.totalCapacity },
        {
          name: "Serial No. of Each Module",
          value: [
            projectDetails?.solarArrayDetails?.serial1ModuleNo,
            projectDetails?.solarArrayDetails?.serial2ModuleNo,
            projectDetails?.solarArrayDetails?.serial3ModuleNo,
            projectDetails?.solarArrayDetails?.serial4ModuleNo,
          ],
        },
      ],
    },
    {
      subheading: "Pump",
      data: [
        { name: "Pump Manufacturer", value: projectDetails?.pumpDetails?.manufacturerName },
        { name: "Brand/ Model", value: projectDetails?.pumpDetails?.brand },
        { name: "Serial Number", value: projectDetails?.pumpDetails?.serialNumber },
        { name: "Pump Type", value: projectDetails?.pumpDetails?.pumpType },
        {
          name: "Pump Capacity(Hp/ Watt)",
          value:
            projectDetails?.pumpDetails?.pumpCapacity +
            " " +
            projectDetails?.pumpDetails?.pumpCapacityUnit,
        },
      ],
    },
    {
      subheading: "Controller",
      data: [
        {
          name: "Controller Manufacturer",
          value: projectDetails?.controllerDetails?.manufacturerName,
        },
        { name: "Brand/ Model", value: projectDetails?.controllerDetails?.brand },
        { name: "Serial Number", value: projectDetails?.controllerDetails?.serialNumber },
        { name: "Controller Type", value: projectDetails?.controllerDetails?.controllerType },
        {
          name: "Controller Capacity(A/ Watt)",
          value:
            projectDetails?.controllerDetails?.controllerCapacity +
            " " +
            projectDetails?.controllerDetails?.controllerCapacityUnit,
        },
      ],
    },
    {
      subheading: "Combiner Box",
      data: [
        { name: "Manufacturer Name", value: projectDetails?.combinerBoxDetails?.manufacturerName },
      ],
    },
    {
      subheading: "Circuit Breaker",
      data: [
        {
          name: "Manufacturer Name",
          value: projectDetails?.circuitBreakerDetails?.manufacturerName,
        },
        { name: "Voltage Rating", value: projectDetails?.circuitBreakerDetails?.voltageRating },
        { name: "Current Rating", value: projectDetails?.circuitBreakerDetails?.currentRating },
      ],
    },
    {
      subheading: "Surge Protection Device(SPD)",
      data: [
        { name: "Manufacturer Name", value: projectDetails?.spdDetails?.manufacturerName },
        { name: "Brand/ Model", value: projectDetails?.spdDetails?.brand },
        { name: "SPD Type", value: projectDetails?.spdDetails?.spdType },
        {
          name: "Maximum Continuous Operating Voltage",
          value: projectDetails?.spdDetails?.operatingVoltage,
        },
      ],
    },
    {
      subheading: "Cable",
      data: [
        { name: "Manufacturer Name", value: projectDetails?.cableDetails?.manufacturerName },
        { name: "Brand/ Model", value: projectDetails?.cableDetails?.brand },
        { name: "Conductor Material", value: projectDetails?.cableDetails?.conductorMaterial },
        {
          name: "Cable",
          value: projectDetails?.cableDetails?.cable,
        },
        { name: "UV Resistance", value: projectDetails?.cableDetails?.uvResistance },
      ],
    },
  ];

  const protectionSystem = [
    {
      subheading: "Air Termination System",
      data: [
        {
          name: "Air Termination Manufacturer",
          value: projectDetails?.airTerminationDetails?.manufacturerName,
        },
        {
          name: "Length of Air Termination rod above the PV (in m)",
          value: projectDetails?.airTerminationDetails?.length,
        },
        {
          name: "Diameter of the Air Termination  rod(mm)",
          value: projectDetails?.airTerminationDetails?.diameter,
        },
        {
          name: "Material of the Air Termination rod",
          value: projectDetails?.airTerminationDetails?.material,
        },
      ],
    },
    {
      subheading: "Down Conductor",
      data: [
        {
          name: "Down Conductor Manufacturer",
          value: projectDetails?.downConductorDetails?.manufacturerName,
        },
        {
          name: "CSA of Down Conductor (in sq. mm)",
          value: projectDetails?.downConductorDetails?.CSA,
        },
        {
          name: "Material of the Air Termination rod",
          value: projectDetails?.downConductorDetails?.material,
        },
      ],
    },
    {
      subheading: "Earth Electrode",
      data: [
        {
          name: "Earth Electrode Manufacturer",
          value: projectDetails?.earthElectrodeDetails?.manufacturerName,
        },
        {
          name: "Length of Earthing Electrode",
          value: projectDetails?.earthElectrodeDetails?.length,
        },
        {
          name: "Material of Earthing Electrode",
          value: projectDetails?.earthElectrodeDetails?.material,
        },
      ],
    },
  ];

  const mountingStructure = [
    {
      name: "Mounting Structure Material",
      value: projectDetails?.mountingStructureDetails?.material,
    },
    {
      name: "Ground Clearance from Bottom PV Edge",
      value: projectDetails?.mountingStructureDetails?.groundClearance,
    },
    {
      name: "Non-Corrosive Fastners",
      value: projectDetails?.mountingStructureDetails?.nonCorrosiveFastner,
    },
  ];

  const navigationLinks = [
    { name: "General Information" },
    {
      name: "Technical Specifications",
      value: technicalSpecifications.map((each) => each.subheading),
    },
    {
      name: "Protection System",
      value: protectionSystem.map((each: any) => each.subheading),
    },
    {
      name: "Mounting Structure",
    },
  ];

  const onClickGo = () => {
    history.goBack();
  };

  return (
    <div style={{ paddingRight: "7rem" }}>
      <div className="page-header" style={{ flex: 1 }}>
        <div className="d-flex p-3">
          <div className="go-back">
            <Button variant="outline-primary" onClick={onClickGo}>
              <ChevronLeft />
              Go Back
            </Button>
          </div>
          <div className="flex-grow-1 text-center">
            <PageTitle title="Project Details" />
          </div>
          {user?.role === "SUPERADMIN" && (
            <Button onClick={() => history.push(`/edit-project/${projectId}/general-information`)}>
              Edit Project Details
            </Button>
          )}
        </div>
        <Divider type="light" size="medium" />
      </div>

      <Row className="my-5">
        <Col xs={12} lg={4}>
          <Nav defaultActiveKey="first" className="flex-column ">
            {navigationLinks.map((each) => {
              return (
                <>
                  <Link
                    key={each.name}
                    style={{ textDecoration: "none" }}
                    className="p-0 mb-1 text-muted"
                    to={`#${each.name}`}
                    replace
                  >
                    {each.name}
                  </Link>
                  {Array.isArray(each.value) &&
                    each.value.map((each2) => {
                      return (
                        <div key={each.name} style={{ fontSize: "0.8rem", paddingLeft: "2rem" }}>
                          <Link
                            style={{ color: "silver" }}
                            className="p-0 nav-link"
                            to={`#${each2}`}
                            replace
                          >
                            {each2}
                          </Link>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </Nav>
        </Col>
        <Col xs={12} lg={8}>
          <Table
            size="sm"
            borderless
            width={500}
            responsive
            className="scroll_table"
            style={{ display: "block", height: "55vh", width: "100%" }}
          >
            {/* <thead>
              <tr>
                <th>testy</th>
                <th>test</th>
              </tr>
            </thead> */}
            <tbody
              style={{
                overflow: "auto",
              }}
            >
              <tr>
                <td>
                  <h4 style={{ color: "silver" }} id="General Information">
                    General Information
                  </h4>
                </td>
              </tr>

              {generalInformation.map((eachSection) => (
                <>
                  {eachSection.map((eachItem) => (
                    <tr key={eachItem.name}>
                      <td>{eachItem.name}</td>

                      <td>
                        {Array.isArray(eachItem.value)
                          ? eachItem.value.map((eachValue) => <p key={eachValue}>{eachValue}</p>) ||
                            "N/A"
                          : eachItem.value || "N/A"}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <br />
                  </tr>
                </>
              ))}
              <tr>
                <h4 style={{ color: "silver" }} id="Technical Specifications">
                  Technical Specifications
                </h4>
              </tr>
              {technicalSpecifications.map((eachSection) => (
                <>
                  <tr id={eachSection.subheading}>
                    <td>
                      <h5 style={{ color: "silver" }}>{eachSection.subheading}</h5>
                    </td>
                  </tr>
                  {eachSection.data.map((eachItem) => (
                    <tr key={eachItem.name}>
                      <td>{eachItem.name}</td>

                      <td>
                        {Array.isArray(eachItem.value)
                          ? eachItem.value.map((eachValue) => <p key={eachValue}>{eachValue}</p>)
                          : eachItem.value}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <br />
                  </tr>
                </>
              ))}
              <tr>
                <h4 style={{ color: "silver" }} id="Protection System">
                  Protection System
                </h4>
              </tr>
              {protectionSystem.map((eachSection) => (
                <>
                  <tr id={eachSection.subheading}>
                    <h5 style={{ color: "silver" }}>{eachSection.subheading}</h5>
                  </tr>
                  {eachSection.data.map((eachItem) => (
                    <tr key={eachItem.name}>
                      <td>{eachItem.name}</td>

                      <td>
                        {Array.isArray(eachItem.value)
                          ? eachItem.value.map((eachValue) => <p key={eachValue}>{eachValue}</p>)
                          : eachItem.value}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <br />
                  </tr>
                </>
              ))}
              <tr>
                <h4 style={{ color: "silver" }} id="Mounting Structure">
                  Mounting Structure
                </h4>
              </tr>
              {mountingStructure.map((eachItem) => (
                <tr key={eachItem.name} id={eachItem.name}>
                  <td>{eachItem.name}</td>

                  <td>
                    {Array.isArray(eachItem.value)
                      ? eachItem.value.map((eachValue) => <p key={eachValue}>{eachValue}</p>)
                      : eachItem.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectDetails;
