import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FilePicker from "../../components/form/FilePicker";
import { Divider, GoBack, LoadingOverlay, PageTitle } from "../../components/shared";
import {
  editOrganizationDetails,
  editSubOrganizationDetails,
  getOrganizationDetail,
  getSubOrganizationDetail,
  uploadFile,
} from "../../../helpers/api";
import { InitialOrganizationData } from "./InitialOrganisationData";
import { OrganizationDataType, SubOrganizationDataType } from "../../ts/types";
import { toast } from "react-toastify";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";
import history from "../../../history";
import { AspectRatio } from "react-bootstrap-icons";

interface EditPayload {
  name: string;
  address: string;
  logoUrl: string;
  createdAt?: Date;
  updatedAt?: Date;
  organizationId?: string;
}

const EditOrganisation = () => {
  const [inputData, setInputData] = useState<OrganizationDataType | SubOrganizationDataType>(
    InitialOrganizationData
  );
  const [uploading, setUploading] = useState<boolean>(false);

  const { organizationId }: { organizationId: string } = useParams();

  const location = useLocation();

  // Create a new URLSearchParams object using the location's search string
  const searchParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter, e.g., 'myQueryParam'
  const isSub = searchParams.get("isSub");

  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      // form data
      const bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);

      return uploadFile(bodyFormData).then((res: any) => {
        if (res.status) {
          setInputData((prevData) => ({ ...prevData, [fileName]: res.data.url }));
          toast.success("File uploaded successfully");
        } else {
          toast.error("Error while uploading the file");
        }
      });
    };

    setUploading(true);
    await upload();
    setUploading(false);
  };

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    const value = e.target.value;
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [keyof OrganizationDataType, any];

    // Deep clone inputData to avoid mutating state directly
    const prevInputData = _.cloneDeep(inputData) as any;

    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);
    // setUploading(false);

    const payload: EditPayload = {
      ...inputData,
    };

    if (payload.createdAt) delete payload.createdAt;

    if (payload.updatedAt) delete payload.updatedAt;

    // We don't need organizationId for editing sub-org
    if (isSub && payload?.organizationId) delete payload.organizationId;

    try {
      let response;

      isSub
        ? (response = await editSubOrganizationDetails(payload))
        : (response = await editOrganizationDetails(payload));

      if (!response?.data) {
        return toast.error("Failed to update data!");
      }

      toast.success("Updated Successfully!");
    } catch (err) {
      toast.error("Failed to update data!");
    } finally {
      setUploading(false);
    }
  };

  const fetchOrganisationData = async () => {
    let response;

    isSub
      ? (response = await getSubOrganizationDetail(organizationId))
      : (response = await getOrganizationDetail(organizationId));

    if (response?.data) {
      setInputData(response.data);
    } else {
      history.push("/organization");
    }
  };

  useEffect(() => {
    fetchOrganisationData();
  }, []);

  return (
    <div>
      <Container>
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title={isSub ? "Edit Sub-Organization" : "Edit Organization"} />
            </div>
          </div>

          <Divider type="light" size="medium" />
        </div>

        <div>
          <Form className="mt-5" onSubmit={handleOnSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="name"
                  value={inputData.name}
                  onChange={handleOnChange("name")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="1">
                Address
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  className="w-50"
                  name="address"
                  // onChange={(e) => {
                  //   setAddress(e.target.value);
                  // }}
                  value={inputData.address}
                  onChange={handleOnChange("address")}
                />
              </Col>
            </Form.Group>

            <Row>
              <Col xl={4} lg={2} md={4} xs={12}>
                <Form.Group as={Row} controlId="formFile">
                  <FilePicker
                    id="profileImageUrl"
                    onFileSelect={onFileSelect("logoUrl")}
                    placeholder="Upload Photo"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xl={4} lg={4} md={4} xs={12}>
                {!!inputData.logoUrl && (
                  <Row>
                    <Col xl={8} lg={8} md={8} xs={12}>
                      <img
                        src={inputData.logoUrl}
                        style={{
                          maxWidth: "150px",
                        }}
                        alt=""
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "flex-start" }}>
                <div className="d-flex pt-4" style={{ display: "flex", alignItems: "flex-start" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="bg-aepc-dark btn-lg w-min-200"
                    style={{ width: "250px", marginTop: "10px" }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
        {uploading && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default EditOrganisation;
