import { SubOrganizationDataType } from "../../ts/types";

export const InitialSubOrganizationData: SubOrganizationDataType = {
  organizationId: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  name: "",
  logoUrl: "",
  address: "",
};
