import { UserDetailsProps } from "../app/context/UserInfoContext";

export const getUserDetails = () => {
  try {
    let x: any = window.localStorage.getItem("user");
    
    const user = JSON.parse(x);

    if (user) {
      return user;
    } else {
      throw new Error("No User");
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
