import React, { ReactElement, useEffect, useState } from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { LoadingOverlay } from "../../components/shared";
import { getProjectDetails, getWeatherData } from "../../../helpers/api";
import { useParams } from "react-router-dom";
import weatherBackground from "../../../assets/img/weatherBackground.png";
import thermometer from "../../../assets/icons/thermometer.png";
import WeatherStatsCard from "./components/WeatherStatsCard";
import { TimeSeriesData } from "../../ts/types";
import { LuClock3 } from "react-icons/lu";
import { FaWind } from "react-icons/fa";
import { FaUmbrella } from "react-icons/fa";
import { FaCloud } from "react-icons/fa";
import { FaWater } from "react-icons/fa";

interface WeatherUnits {
  air_pressure_at_sea_level: string;
  air_temperature: string;
  cloud_area_fraction: string;
  precipitation_amount: string;
  relative_humidity: string;
  wind_from_direction: string;
  wind_speed: string;
}

interface WeatherComponentProps {
  refreshingWeather: boolean;
  resetRefresh: () => void;
}

const Weather: React.FC<WeatherComponentProps> = ({ refreshingWeather, resetRefresh }) => {
  const [uploading, setUploading] = useState(false);

  const [location, setLocation] = useState({ latitude: 0, longitude: 0, altitude: 0 });
  const [weatherData, setWeatherData] = useState<any[]>([]);
  const [weatherDataArray, setWeatherDataArray] = useState<any[]>([]);
  const [todaysData, setTodaysData] = useState<TimeSeriesData>();

  const [currentWeather, setCurrentWeather] = useState<TimeSeriesData>();

  const { projectId } = useParams<{ projectId: string }>();
  const [weatherUnits, setWeatherUnits] = useState<WeatherUnits>({
    air_pressure_at_sea_level: "",
    air_temperature: "",
    cloud_area_fraction: "",
    precipitation_amount: "",
    relative_humidity: "",
    wind_from_direction: "",
    wind_speed: "",
  });

  //format date into the "5 Aug" format
  const formatDateSingleDigitDay = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-GB", { month: "short" }).format(date);
    return `${day} ${month}`;
  };

  const formatDayAndTime = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-GB", { month: "long" }).format(date);
    return `${day} ${month}`;
  };

  // format time in 18:00 format
  const timeFormat = (initialTime: string) => {
    const time = new Date(initialTime).toISOString().split("T")[1].split(":").slice(0, 2).join(":");
    return time;
  };

  // filter unique dates
  const targetTime = "12:00";

  const filterWeatherData = (timeseries: any) => {
    const dateSet = new Set();
    const filteredData: any = [];

    timeseries.forEach((weather: any) => {
      const date = new Date(weather.time);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const time = `${hours}:${minutes}`;

      if (time === targetTime) {
        const dateOnly = date.toISOString().split("T")[0];
        if (!dateSet.has(dateOnly)) {
          dateSet.add(dateOnly);
          filteredData.push(weather);
        }
      }
    });

    return filteredData.slice(0, 6);
  };

  const todaysWeatherData = (timeSeriesData: any) => {
    const today = new Date().toISOString().split("T")[0];
    return timeSeriesData?.properties?.timeseries?.filter((entry: any) => {
      // Extract date from time string
      const entryDate = entry.time.split("T")[0];
      return entryDate === today;
    });
  };

  function getCurrentDateTimeWithZeroedMinutesSecondsInUTC() {
    const localDate = new Date();
    // Convert local date and time to UTC
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    // Set minutes, seconds, and milliseconds to zero
    utcDate.setUTCMinutes(0);
    utcDate.setUTCSeconds(0);
    utcDate.setUTCMilliseconds(0);
    // Format the date to ISO 8601 string with ‘Z’ at the end
    const isoString = utcDate.toISOString().replace(/\.\d{3}Z$/, "Z");
    return isoString;
  }

  function getCurrentClosestWeatherData(data: any) {
    const adjustedUTCDateTime = getCurrentDateTimeWithZeroedMinutesSecondsInUTC();
    return data?.properties?.timeseries?.find((weather: any) => {
      return weather.time === adjustedUTCDateTime;
    });
  }

  function calculateAverages(timeSeries: any) {
    const dailyData: any = {};

    timeSeries.forEach((item: any) => {
      if (!item.time || !item.data || !item.data.instant || !item.data.instant.details) {
        console.warn('Missing data in item:', item);
        return;
      }

      const day = item.time.split('T')[0]; // Get the day part from the timestamp

      if (!dailyData[day]) {
        dailyData[day] = {
          windSpeed: [],
          cloudAreaFraction: [],
          relativeHumidity: [],
          currentTemperature: [],
          precipitationAmount: []
        };
      }

      const details = item.data.instant.details;
      const precipitationAmount = item.data.next_1_hours?.details?.precipitation_amount || 0;

      dailyData[day].windSpeed.push(details.wind_speed);
      dailyData[day].cloudAreaFraction.push(details.cloud_area_fraction);
      dailyData[day].relativeHumidity.push(details.relative_humidity);
      dailyData[day].currentTemperature.push(details.air_temperature);
      dailyData[day].precipitationAmount.push(precipitationAmount);
    });

    // Function to calculate average and round to two decimal places
    const average = (arr: any) => arr.length ? (arr.reduce((sum: number, val: number) => sum + val, 0) / arr.length).toFixed(2) : '0.00';

    // Calculate averages and format the data
    const averagedData = Object.keys(dailyData).slice(0, 6).map(day => {
      const data = dailyData[day];

      return {
        time: day,
        data: {
          instant: {
            details: {
              wind_speed: parseFloat(average(data.windSpeed)),
              cloud_area_fraction: parseFloat(average(data.cloudAreaFraction)),
              relative_humidity: parseFloat(average(data.relativeHumidity)),
              air_temperature: parseFloat(average(data.currentTemperature))
            }
          },
          next_1_hours: {
            details: {
              precipitation_amount: parseFloat(average(data.precipitationAmount))
            }
          }
        }
      };
    });

    return averagedData;
  }

  // const filteredWeatherData = filterWeatherData(weatherDataArray[0]?.properties?.timeseries || []);
  const filteredWeatherData = calculateAverages(weatherDataArray[0]?.properties?.timeseries || []);


  useEffect(() => {
    setUploading(true);
    const fetchProjectDetails = async () => {
      try {
        const res = await getProjectDetails(projectId);
        setLocation({
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          altitude: res.data.altitude,
        });
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProjectDetails();
    setUploading(false);
  }, [projectId]);

  const stopLoading = () => {
    setTimeout(() => {
      resetRefresh();
    }, 500);
  };

  useEffect(() => {
    setUploading(true);
    if (location && location.latitude !== 0 && location.longitude !== 0) {
      const { latitude, longitude, altitude } = location;

      const fetchWeatherData = async () => {
        try {
          const res = await getWeatherData(latitude, longitude, altitude);
          setWeatherData(res.data);
          setWeatherDataArray([res.data]);
          const currentWeatherData = todaysWeatherData(res.data);
          setTodaysData(currentWeatherData);
          const closestWeatherData = getCurrentClosestWeatherData(res.data);
          setCurrentWeather(closestWeatherData);
        } catch (error) {
          console.error("Error fetching weather data:", error);
        }
      };
      fetchWeatherData();
      setUploading(false);
      stopLoading();
    }
  }, [location, refreshingWeather]);

  // Extracted weather information from the data

  const airTemperature = currentWeather?.data.instant.details.air_temperature;

  const windSpeed = currentWeather?.data.instant.details.wind_speed;

  const relativeHumidity = currentWeather?.data.instant.details.relative_humidity;

  const cloudAreaFraction = currentWeather?.data.instant.details.cloud_area_fraction;

  const precipitationAmount = currentWeather?.data.next_1_hours.details.precipitation_amount;
  // @ts-ignore
  const updatedAt = weatherData?.properties?.meta?.updated_at;

  // extracted units for the data
  // @ts-ignore
  const airTemperatureUnit = weatherData?.properties?.meta.units.air_temperature;
  // @ts-ignore
  const windSpeedUnit = weatherData?.properties?.meta.units.wind_speed;
  // @ts-ignore
  const precipitationAmountUnit = weatherData?.properties?.meta.units.precipitation_amount;
  // @ts-ignore
  const relativeHumidityUnit = weatherData?.properties?.meta.units.relative_humidity;
  // @ts-ignore
  const cloudAreaFractionUnit = weatherData?.properties?.meta.units.cloud_area_fraction;

  const WeatherDataCell = ({
    icon,
    label,
    value,
    unit,
  }: {
    icon: ReactElement;
    label: string;
    value: number | undefined;
    unit: string;
  }) => {
    return (
      <Col xs={12} md={6} lg={3}>
        <div className="d-flex align-items-center justify-content-md-center ">
          <div className="d-flex flex-wrap align-items-center border border-black my-4 p-3">
            <div className="d-flex flex-column align-items-center text-nowrap">
              {icon}
              <span className="weather-cell">{label}</span>
            </div>
            <span className="ps-2 fw-bold">
              {value}
              {unit}
            </span>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Container
      fluid
      className="mt-5"
      style={{
        // border: "1.5px solid silver",
        borderRadius: "5px",
        fontWeight: "normal",
        backgroundImage: `url(${weatherBackground})`,
        backgroundSize: "cover", // Adjust as needed
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "3rem",
        gap: "2rem",
      }}
    >
      {weatherData ? (
        <>
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.52)",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem 1rem 2rem 1rem",
              borderRadius: "2px",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img src={thermometer} alt="temperature" />
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "white", fontWeight: "500" }}>{airTemperature}</h1>
                <span style={{ color: "white", fontSize: "2rem" }}>°C</span>
              </div>
            </div>

            <Row className="justify-content-between w-100">
              <WeatherDataCell
                icon={<FaWind size={48} />}
                label="हावाको गती"
                value={windSpeed}
                unit={windSpeedUnit}
              />
              <WeatherDataCell
                icon={<FaUmbrella size={48} />}
                label="आद्रता"
                value={relativeHumidity}
                unit={relativeHumidityUnit}
              />
              <WeatherDataCell
                icon={<FaCloud size={48} />}
                label="बादल"
                value={cloudAreaFraction}
                unit={cloudAreaFractionUnit}
              />
              <WeatherDataCell
                icon={<FaWater size={48} />}
                label="वर्षा"
                value={precipitationAmount}
                unit={precipitationAmountUnit}
              />
            </Row>
          </div>

          <Container fluid>
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.52)",
                padding: "1rem 1rem 2rem 1rem",
                borderRadius: "2px",
              }}
            >
              {todaysData && Array.isArray(todaysData) && todaysData.length > 0 && (
                <div className="table-responsive mt-4 position-relative">
                  <Table responsive>
                    <thead>
                      <tr
                        style={{
                          fontWeight: "normal",
                          backgroundColor: "transparent",
                          border: "0px none transparent",
                        }}
                      >
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "transparent",
                            color: "rgba(49, 48, 51, 1)",
                            verticalAlign: "middle",
                            paddingLeft: 0,
                          }}
                        >
                          <LuClock3 size={24} />
                        </th>
                        {todaysData.map((weather: any, i: number) => (
                          <th
                            style={{
                              textAlign: "left",
                              backgroundColor: "transparent",
                              color: "rgba(49, 48, 51, 1)",
                              padding: "10px",
                              borderBottom: "1px solid lightgrey",
                            }}
                            key={i}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ color: "gray" }}>{timeFormat(weather.time)}</p>
                              <span> {weather.data.instant.details.air_temperature}°</span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                          <FaWind size={24} />
                        </td>
                        {todaysData.map((weather: any, i: number) => (
                          <td className="weather-cell padding-10" key={i}>
                            {weather.data.instant.details.wind_speed}
                            {windSpeedUnit}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                          <FaUmbrella size={24} />
                        </td>
                        {todaysData.map((weather: any, i: number) => (
                          <td className="weather-cell padding-10" key={i}>
                            {weather.data.instant.details.relative_humidity}
                            {relativeHumidityUnit}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                          <FaCloud size={24} />
                        </td>
                        {todaysData.map((weather: any, i: number) => (
                          <td className="weather-cell padding-10" key={i}>
                            {weather.data.instant.details.cloud_area_fraction}
                            {cloudAreaFractionUnit}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                          <FaWater size={24} />
                        </td>
                        {todaysData.map((weather: any, i: number) => (
                          <td className="weather-cell padding-10" key={i}>
                            {weather.data.next_1_hours.details.precipitation_amount}
                            {precipitationAmountUnit}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </Container>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "1rem",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "1rem 2rem",
                minWidth: "25%",
                borderRadius: 5,
              }}
            >
              <p style={{ fontWeight: "600" }}>मौसम पूर्वानुमान (छ दिनको)</p>
            </div>
            <div
              style={{
                // display: "grid",
                // gridTemplateColumns: "repeat(6, 1fr)",
                display: "flex",
                overflowX: "scroll",
                gap: "10px",
                width: "100%",
              }}
            >
              {filteredWeatherData.map((weather: any, i: number) => (
                <WeatherStatsCard
                  windSpeed={weather?.data?.instant?.details?.wind_speed}
                  cloudAreaFraction={weather?.data?.instant?.details?.cloud_area_fraction}
                  relativeHumidity={weather?.data?.instant?.details?.relative_humidity}
                  precipitationAmount={weather?.data?.next_1_hours?.details?.precipitation_amount}
                  currentTemperature={weather?.data?.instant?.details?.air_temperature}
                  curentDayAndTime={formatDayAndTime(weather?.time)}
                  key={i}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center", color: "white", padding: "2rem" }}>
          <h3>मौसम डेटा उपलब्ध छैन। कृपया पुनः प्रयास गर्नुहोस्।</h3>
        </div>
      )}

      {uploading || (refreshingWeather && <LoadingOverlay />)}
    </Container>
  );
};

export default Weather;
