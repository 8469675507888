import React from "react";
import { Router, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// History
import history from "../history";
// Contexts
import { LayoutProvider } from "./context/LayoutContext";
import UserInfoProvider from "./context/UserInfoContext";
// Views
import SignIn from "./views/auth/SignIn";
// Layout
import Layout from "./layout/Layout";
// Components
import { PrivateRoute, PublicRoute } from "./components/routes/Route";

const App: React.FC = () => (
  <UserInfoProvider>
    <LayoutProvider>
      <Router history={history}>
        <Switch>
          <PublicRoute exact path="/sign-in" component={SignIn} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </Router>
      <ToastContainer autoClose={10000} position={toast.POSITION.TOP_RIGHT} />
    </LayoutProvider>
  </UserInfoProvider>
);

export default App;
