import React from "react";
import Logo from "../../../assets/img/logo.svg";

const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        marginTop: "50px",
      }}
    >
      <h6 style={{ marginBottom: "0", marginTop: "5px" }}>Powered By</h6>
      <img src={Logo} style={{ width: "80px" }} alt="" />
    </div>
  );
};

export default Footer;
