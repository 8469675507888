import { BeneficiaryDataType } from "../../ts/types";
import landArea from "../../../storage/landArea/landArea.json";

export const InitialBeneficiaryData: BeneficiaryDataType = {
  projectId: "",
  phoneNumber: "",
  name: "",
  landArea: "",
  crops: [],
  landAreaUnit: landArea[0],
};
