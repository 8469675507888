import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { BiMessageSquareEdit } from "react-icons/bi";
import { getMessageLogs, scheduleSms } from "../../../../helpers/api";
import { ScheduleDataType } from "../../../ts/types";
import { LoadingOverlay } from "../../../components/shared";
import { toast } from "react-toastify";
import { useUserInfoContext } from "../../../context/UserInfoContext";
import { FaRegCalendarAlt } from "react-icons/fa";

interface MessageData {
  id: string;
  projectId: string;
  phoneNumber: string;
  message: string;
  scheduledDate: string;
  sent: boolean;
  createdAt: string;
  updatedAt: string;
}

const initialInputData: ScheduleDataType = {
  message: "",
  date: "",
  time: "",
  projectId: "",
};

const MessageLogs = (projectIdNumber: any) => {
  const [inputData, setInputData] = useState<ScheduleDataType>(initialInputData);
  const [message, setMessage] = useState<MessageData[]>([]);
  const [uploading, setUploading] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const { user }: any = useUserInfoContext();

  const handleModalToggle = () => {
    setShow(!show);
  };

  const handleOnChange =
    (inputKey: keyof ScheduleDataType) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputData((prevData) => ({
        ...prevData,
        [inputKey]: value,
      }));
    };

  useEffect(() => {
    setProjectId(projectIdNumber.projectIdNumber);
  }, [projectIdNumber]);

  useEffect(() => {
    inputData.projectId = projectId;
  }, [projectId]);

  // const handleOnSubmit = () => {
  //   setIsBtnDisabled(true);
  //   if (!inputData.projectId || !inputData.message || !inputData.date || !inputData.time) {
  //     toast.error("Please fill all the necessary fields");
  //     setUploading(false);
  //   } else {
  //     scheduleSms(inputData)
  //       .then((res: any) => {
  //         if (res.status) {
  //           toast.success("Successfully Scheduled SMS");
  //           handleModalToggle();
  //         } else {
  //           toast.error("Error scheduling SMS");
  //         }
  //       })
  //       .finally(() => setUploading(false));
  //   }
  // };

  const handleOnSubmit = () => {
    // Disable the button immediately
    setIsBtnDisabled(true);

    if (!inputData.projectId || !inputData.message || !inputData.date || !inputData.time) {
      toast.error("Please fill all the necessary fields");
      setIsBtnDisabled(false); // Re-enable the button if there's an error
      setUploading(false);
      return;
    }

    setUploading(true);

    scheduleSms(inputData)
      .then((res: any) => {
        if (res.status) {
          toast.success("Successfully Scheduled SMS");
          handleModalToggle();
          // Refetch the messages
          allMessageLogs();
          // history.push(`/project`);
        } else {
          toast.error("Error scheduling SMS");
        }
      })
      .catch((error) => {
        toast.error("An unexpected error occurred");
        console.error("Scheduling SMS failed:", error);
      })
      .finally(() => {
        setIsBtnDisabled(false); // Re-enable the button after submission
        setUploading(false);
      });
  };

  const allMessageLogs = useCallback(() => {
    if (!projectId) return;
    try {
      getMessageLogs(projectId).then((res: any) => {
        setMessage(res.data);
      });
    } catch (exception) {
      console.log("Error", exception);
    }
  }, [projectId]);

  const handleReschedule = (info: string, scheduledDate: string) => {
    const date = new Date(scheduledDate);
    const formattedDate = date.toISOString().split("T")[0];
    const formattedTime = date.toISOString().split("T")[1].substring(0, 5);
    inputData.message = info;
    inputData.date = formattedDate;
    inputData.time = formattedTime;
    handleModalToggle();
  };

  useEffect(() => {
    setLoading(true);
    allMessageLogs();
    setLoading(true);
  }, [projectId]);

  return (
    <div>
      <Container
        fluid
        className="mt-5"
        style={{
          border: "1.5px solid silver",
          borderRadius: "10px",

          fontWeight: "normal",
        }}
      >
        <Row
          style={{
            borderRadius: "10px",
            backgroundColor: "rgb(230, 230, 230)",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Col md={7} xs={6} className="pt-2">
            <p>
              <b>Message Logs</b>
            </p>
          </Col>
          {user?.role === "SUPERADMIN" && (
            <Col md={5} xs={6}>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  style={{ fontSize: "0.8rem", marginRight: "5px" }}
                  onClick={handleModalToggle}
                >
                  <BiMessageSquareEdit size={20} style={{ marginRight: "8px" }} />
                  <b>Schedule New One</b>
                </Button>
              </div>
            </Col>
          )}
        </Row>
        <Table responsive>
          <thead className="farmerDetail__thead">
            <tr
              style={{
                borderBottom: "1.5px solid silver",
                fontWeight: "normal",
              }}
            >
              <th>Date</th>

              <th>Time</th>

              <th>Messsage</th>

              <th>Status</th>

              <th>Reschedule</th>
            </tr>
          </thead>
          <tbody>
            {message?.length > 0 ? (
              message?.map((msg, index) => (
                <tr
                  style={{
                    marginTop: 10,
                    fontWeight: "normal",
                  }}
                  key={index}
                >
                  <td
                    style={{
                      padding: "1.5rem 0.5rem",
                    }}
                  >
                    {msg.scheduledDate ? new Date(msg.scheduledDate).toLocaleDateString() : "N/A"}
                  </td>
                  <td
                    style={{
                      padding: "1.5rem 0.5rem",
                    }}
                  >
                    {msg.scheduledDate
                      ? new Date(
                          new Date(msg.scheduledDate).toLocaleString("en-US", { timeZone: "UTC" })
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "N/A"}
                  </td>
                  <td
                    style={{
                      padding: "1.5rem 0.5rem",
                    }}
                  >
                    {msg.message ? msg.message : "N/A"}
                  </td>
                  <td
                    style={{
                      padding: "1.5rem 0.5rem",
                    }}
                  >
                    {typeof msg.sent === "boolean" ? (msg.sent ? "Sent" : "Pending") : "N/A"}
                    {/* {typeof msg.sent === "boolean" ? String(msg.sent) : "N/A"} */}
                  </td>
                  <td
                    style={{
                      padding: "1.5rem 0.5rem",
                    }}
                  >
                    <button
                      onClick={() => handleReschedule(msg.message, msg.scheduledDate)}
                      className="btn btn-link"
                      style={{
                        padding: 0,
                      }}
                    >
                      <FaRegCalendarAlt />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No data available!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {uploading && <LoadingOverlay />}
      </Container>

      <Modal show={show} onHide={handleModalToggle} centered>
        <Modal.Body
          style={{
            padding: "0 2rem 2rem 3rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form
            className="mt-5 w-100"
            // onSubmit={() => {
            //   handleOnSubmit();
            // }}
            style={{}}
          >
            {/* <Form.Group className="mb-3" style={{ display: "flex", alignItems: "center" }}> */}
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3" className="form-Modal-label">
                Message
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="form-Modal-label"
                  value={inputData.message}
                  onChange={handleOnChange("message")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="3" className="form-Modal-label">
                Date
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="date"
                  className="w-100 form-Modal-label"
                  name="name"
                  value={inputData.date}
                  onChange={handleOnChange("date")}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="3" className="form-Modal-label">
                Time
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="time"
                  className="w-100 form-Modal-label"
                  name="Time"
                  value={inputData.time}
                  onChange={handleOnChange("time")}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>
          </Form>
          <div style={{ marginTop: "30px" }}>
            <Button
              variant="primary"
              style={{
                fontSize: "0.8rem",
                marginRight: "5px",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleOnSubmit}
            >
              <b>Schedule</b>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MessageLogs;
