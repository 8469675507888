import cloud from "../../../../assets/icons/cloud.png";
import rain from "../../../../assets/icons/rain.png";
import wave from "../../../../assets/icons/wave.png";
import wind from "../../../../assets/icons/wind.png";

import { FaThermometerEmpty } from "react-icons/fa";

const WeatherStatsCard = ({
  windSpeed,
  cloudAreaFraction,
  relativeHumidity,
  precipitationAmount,
  currentTemperature,
  curentDayAndTime,
}) => {
  return (
    <div className="weatherStatsCard">
      <div className="date-temp">
        <p className="fw-medium">{curentDayAndTime}</p>

        <span style={{ display: "inline-flex", alignItems: "center", marginLeft: -4 }}>
          <FaThermometerEmpty />
          {currentTemperature}°C
        </span>
      </div>

      <div className="weatherDetails">
        <div className="weatherIconContainer">
          <img src={wind} alt="wind" />
          <p>{windSpeed}m/s</p>
        </div>
        <div className="weatherIconContainer">
          <img src={rain} alt="humidity" />
          <p>{relativeHumidity}%</p>
        </div>
        <div className="weatherIconContainer">
          <img src={cloud} alt="cloud" />
          <p>{cloudAreaFraction}%</p>
        </div>
        <div className="weatherIconContainer">
          <img src={wave} alt="precipitation" />
          <p>{precipitationAmount ? precipitationAmount : 0}mm</p>
        </div>
      </div>
    </div>
  );
};

export default WeatherStatsCard;
