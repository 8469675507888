import { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

//Helpers
import { getFarmerById } from "../../../../helpers/api";

//Types
import { ProjectDataType } from "../../../ts/types";

// Assests
import LocationIcon from "../../../../assets/icons/locationIcon.png";
import EyeIcon from "../../../../assets/icons/eyeIcon.png";
import PhoneIcon from "../../../../assets/icons/contactIcon.png";
import CalendarIcon from "../../../../assets/icons/calendarIcon.png";
import GeneralInfoIcon from "../../../../assets/icons/informationIcon.png";
import EquipmentIcon from "../../../../assets/icons/equipmentsIcon.png";
import DocumentsIcon from "../../../../assets/icons/documentsIcon.png";
import LogsIcon from "../../../../assets/icons/logsIcon.png";
import EditIcon from "../../../../assets/icons/editIcon.png";
import EyeBlue from "../../../../assets/icons/blueEye.png";
import { useUserInfoContext } from "../../../context/UserInfoContext";

const GeneralInfo = ({ org }: { org?: { name: string; id: string } }) => {
  let x: any = window.localStorage.getItem("user");
  const monitoredBy = org?.name || "Gham Power Nepal";

  const { projectId } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(false as unknown as ProjectDataType);

  const { user }: any = useUserInfoContext();

  useEffect(() => {
    getFarmerById(projectId)
      .then((res: any) => {
        setUserInfo(res.data);
      })

      .finally(() => { });
  }, [projectId]);

  return (
    <div>
      <div>
        <Row>
          <h4 style={{ margin: "1rem 0rem 2rem 0rem " }}>{userInfo.beneficiaryName}</h4>
        </Row>

        <Row
          style={{
            width: "52%",
            // position: "sticky",
            top: 0,
            backgroundColor: "white",
          }}
        >
          <Col xl={4} lg={12} md={12} sm={12} xs={12} className="p-0 m-0 infoNav_Btn ">
            <Button variant="outline-info" className="outline_info">
              <a className="nav__projectStatus" href="#General Information">
                <img src={GeneralInfoIcon} className="generalInfoNav_Icons" />
                General Information
              </a>
            </Button>
          </Col>
          <Col xl={3} lg={12} md={12} sm={12} xs={12} className="p-0 m-0  ">
            <Button variant="outline-info" className="outline_info">
              <a href="#Equipments" className="nav__projectStatus">
                <img src={EquipmentIcon} className="generalInfoNav_Icons" />
                Equipments
              </a>
            </Button>
          </Col>
          <Col xl={3} lg={12} md={12} sm={12} xs={12} className="p-0 m-0  ">
            <Button variant="outline-info" className="outline_info">
              <a className="nav__projectStatus" href="#Documents">
                <img src={DocumentsIcon} className="generalInfoNav_Icons" />
                Documents
              </a>
            </Button>
          </Col>
          <Col xl={2} lg={12} md={12} sm={12} xs={12} className="p-0 m-0  ">
            <Button variant="outline-info" className="outline_info">
              <a className="nav__projectStatus" href="#logs">
                <img src={LogsIcon} className="generalInfoNav_Icons" />
                Logs
              </a>
            </Button>
          </Col>
        </Row>
        <hr
          style={{
            backgroundColor: "silver",
            border: "1px silver solid",
            margin: "2rem 0",
          }}
        />
        <p
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "silver",
          }}
          id="General Information"
        >
          General Information
        </p>
        <Row>
          <Col xl={3} lg={12} md={12} sm={12} xs={12}>
            <img
              src={userInfo.profileImageUrl}
              style={{
                height: "13rem",
                width: "14rem",
              }}
            />
          </Col>
          <Col xl={5} lg={12} md={12} sm={12} xs={12} className="generalInfo_Name">
            <p
              style={{
                fontSize: "1.4rem",
                fontWeight: "bold",
              }}
            >
              {userInfo.beneficiaryName}
            </p>

            <p
              style={{
                fontSize: "0.9rem",
              }}
            >
              <img src={LocationIcon} className="generalInfo_Icons" />
              {userInfo && userInfo.address.municipality}, ward no.
              {userInfo && userInfo.address.ward},{userInfo && userInfo.address.region}, Nepal
            </p>

            <p
              style={{
                fontSize: "0.9rem",
              }}
            >
              <img src={EyeIcon} className="generalInfo_Icons" />
              Monitored by{" "}
              <b style={{ borderBottom: "1px dotted", marginLeft: "2px" }}>{monitoredBy}</b>
              <img src={PhoneIcon} className="generalInfo_Icons" style={{ marginLeft: "45px" }} />
              {userInfo.phoneNumber}
            </p>

            <p
              style={{
                fontSize: "0.9rem",
              }}
            >
              <img src={CalendarIcon} className="generalInfo_Icons" />
              {userInfo && userInfo.createdAt.split("T")[0]}
            </p>
          </Col>
          {/* {user?.role === "SUPERADMIN" && (
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Link to={`/project-status/${userInfo.id}/editInfo`}>
                <Button
                  variant="outline-primary"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "8px",
                    border: "3px solid",
                    minWidth: "150px",
                  }}
                >
                  <img src={EditIcon} className="generalInfo_Icons" />
                  <b>Edit Information </b>
                </Button>
              </Link>
              <Link to={`/project/beneficiaries/${projectId}`}>
                <Button
                  variant="outline-primary"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "8px",
                    border: "3px solid",
                    minWidth: "150px",
                    marginLeft: "10px"
                  }}
                >
                  <b>View Beneficiary </b>
                </Button>
              </Link>
            </Col>

          )} */}
          <Col xl={4} lg={12} md={12} sm={12} xs={12}>
            {user?.role === "SUPERADMIN" && (
              <Link to={`/project-status/${userInfo.id}/editInfo`}>
                <Button
                  variant="outline-primary"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "8px",
                    border: "3px solid",
                    minWidth: "150px",
                  }}
                >
                  <img src={EditIcon} className="generalInfo_Icons" />
                  <b>Edit Information</b>
                </Button>
              </Link>
            )}
            <Link to={`/project/beneficiaries/${projectId}`}>
              <Button
                variant="outline-primary"
                style={{
                  fontSize: "0.8rem",
                  borderRadius: "8px",
                  border: "3px solid",
                  minWidth: "150px",
                  marginLeft: user?.role === "SUPERADMIN" ? "10px" : "0px",
                }}
              >
                <b>View Beneficiary</b>
              </Button>
            </Link>
          </Col>
        </Row>
        {/* <Row style={{ marginTop: 20 }}>
          <Col xl={3} lg={12} md={12} sm={12} xs={12}></Col>
          <Col xl={5} lg={12} md={12} sm={12} xs={12} className="generalInfo_Name"></Col>
          <Col xl={3} lg={12} md={12} sm={12} xs={12}>
            <Link to={`/project/beneficiaries/${projectId}`}>
              <Button
                variant="outline-primary"
                style={{
                  fontSize: "0.8rem",
                  borderRadius: "8px",
                  border: "3px solid",
                  minWidth: "150px",
                }}
              >
                <b>View Beneficiary </b>
              </Button>
            </Link>
          </Col>
        </Row> */}
      </div>
      <hr
        style={{
          backgroundColor: "silver",
          border: "1px silver solid",
          margin: "2rem 0 4rem 0",
        }}
      />
    </div>
  );
};

export default GeneralInfo;
