const resetFilterbtn = () => {
  let dropDownProvince: any = document.getElementById("resetFilterProvince");
  let dropDownDistrict: any = document.getElementById("resetFilterDistrict");
  let dropDownStatus: any = document.getElementById("resetFilterStatus");
  let dropDownActivity: any = document.getElementById("resetFilterActivity");
  dropDownProvince.selectedIndex = 0;
  dropDownDistrict.selectedIndex = 0;
  dropDownStatus.selectedIndex = 0;
  dropDownActivity.selectedIndex = 0;
};

export default resetFilterbtn;
