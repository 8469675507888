import { setupMaster } from "cluster";
import React, { useState, useContext, useEffect } from "react";
import { farmerInfo } from "../../helpers/api";
import { getUserDetails } from "../../helpers/localStorage";
import { useLocation } from "react-router-dom";

//type

export type UserDetailsProps = {
  email: String;
  token: string;
  name: string;
  role: string;
};

// type UserState = {
//   user: any;
// //   setUser: any;
// };

const UserInfoContext = React.createContext({});
const AuthenticatedContext = React.createContext(false);
const SetAuthenticatedContext = React.createContext((value: boolean) => {});
const RemoveAuthenticationContext = React.createContext(() => {});

export const useUserInfoContext = () => {
  return useContext(UserInfoContext);
};

const UserInfoProvider = (props: any) => {
  const [user, setUser] = useState(getUserDetails());
  //   const [authenticated, setAuthenticated] = useState(false);

  //   let location = useLocation();
  //   console.log(location, "check check1");

  //   useEffect(() => {
  //     console.log(location, "check check");
  //     if (location.pathname !== "/sign-in") {
  //       farmerInfo().catch((err) => {
  //         console.log(err);
  //       });
  //     }
  //   }, []);

  return (
    <UserInfoContext.Provider value={{ user, setUser }}>{props.children}</UserInfoContext.Provider>
  );
};

export default UserInfoProvider;
