import React, { ChangeEvent, useState, useEffect } from "react";
import { Row, Col, Form, Button, ProgressBar, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../../history";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../../components/shared";
import styles from "../../../components/form/Switch.module.scss";
// Types
import {
  SolarArrayDetailsType,
  ProjectDataType,
  IrrigationLandDetailsType,
  AddressType,
  OtherContributionType,
  WaterSourceDetailsType,
  PumpDetailsType,
  ControllerDetailsType,
} from "../../../ts/types";
// Helpers
import { getFarmerById, updateProject } from "../../../../helpers/api";
// Data
import { InitialProjectData } from "../InitialProjectData";

const TechinicalSpecification2: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getFarmerById(projectId)
      .then((res: any) => {
        setInputData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [projectId]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [
      keyof ProjectDataType,
      (
        | keyof AddressType
        | keyof IrrigationLandDetailsType
        | keyof OtherContributionType
        | keyof WaterSourceDetailsType
        | keyof SolarArrayDetailsType
        | keyof PumpDetailsType
        | keyof ControllerDetailsType
      )
    ];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    updateProject(inputData).then((res: any) => {
      if (res.status) {
        toast.success("Project updated successfully");
        setSubmitting(false);
        history.push(`/edit-project/${projectId}/technical-specification-3`);
      } else {
        toast.error("Error while updating the project");
      }
    });
  };

  return (
    <div>
      <Container className="form_container">
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Project Details" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>

        <div className="technical-specifications m-l-5 my-5 form_cover">
          <Form onSubmit={handleOnSubmit}>
            <div className="progress-container mb-5">
              <h4 className="mb-3">Technical Specification</h4>
              <Row>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={50} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={0} className="custom-progress-bar" />
                </Col>
              </Row>
            </div>

            <h4 className="text-grey mb-2">Pump</h4>
            <Row className="mb-5">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Pump Manufacturer </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Pumb Manufacturer"
                    value={inputData.pumpDetails.manufacturerName}
                    onChange={handleOnChange("pumpDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Brand/ Model</Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Enter brand model"
                    value={inputData.pumpDetails.brand}
                    onChange={handleOnChange("pumpDetails-brand")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Serial Number</Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Manufacturer"
                    value={inputData.pumpDetails.serialNumber}
                    onChange={handleOnChange("pumpDetails-serialNumber")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Pumb Type</Form.Label>
                  <div className="form-inline">
                    <input
                      type="radio"
                      name="property_typesii_id"
                      className={styles.customRadio}
                      id="custom-radiosii-1"
                      checked={inputData.pumpDetails.pumpType === "surface"}
                      onChange={handleOnChange("pumpDetails-pumpType")}
                      value="surface"
                    />
                    <label htmlFor="custom-radiosii-1" style={{ fontSize: "1rem" }}>
                      Surface
                    </label>
                    <input
                      type="radio"
                      name="property_typesii_id"
                      className={styles.customRadio}
                      id="custom-radiosii-2"
                      checked={inputData.pumpDetails.pumpType === "submersible"}
                      onChange={handleOnChange("pumpDetails-pumpType")}
                      value="submersible"
                    />
                    <label htmlFor="custom-radiosii-2" style={{ fontSize: "1rem" }}>
                      Submersible
                    </label>
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Pumb Capacity (HP/Watt)</Form.Label>
                  <div className="d-flex">
                    <div className="w-50">
                      <Form.Control
                        required
                        className="form-design"
                        type="number"
                        placeholder="Enter the pump capacity"
                        value={inputData.pumpDetails.pumpCapacity}
                        onChange={handleOnChange("pumpDetails-pumpCapacity")}
                      />
                    </div>
                    <div className="w-50 px-3 inline-radio">
                      <Form.Check
                        inline
                        label="Hp"
                        name="pumpCapacity"
                        type="radio"
                        id={`inline-pumpCapacity-1`}
                        checked={inputData.pumpDetails.pumpCapacityUnit === "Hp"}
                        onChange={handleOnChange("pumpDetails-pumpCapacityUnit")}
                        value="Hp"
                      />
                      <Form.Check
                        inline
                        label="Watt"
                        name="pumpCapacity"
                        type="radio"
                        id={`inline-pumpCapacity-2`}
                        checked={inputData.pumpDetails.pumpCapacityUnit === "Watt"}
                        onChange={handleOnChange("pumpDetails-pumpCapacityUnit")}
                        value="Watt"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <h4 className="text-grey mb-2">Controller</h4>
            <Row className="mb-5">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label> Controller Manufacturer </Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Pumb Manufacturer"
                    value={inputData.controllerDetails.manufacturerName}
                    onChange={handleOnChange("controllerDetails-manufacturerName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Brand/ Model</Form.Label>
                  <Form.Control
                    required
                    className="form-design"
                    type="text"
                    placeholder="Enter brand model"
                    value={inputData.controllerDetails.brand}
                    onChange={handleOnChange("controllerDetails-brand")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Serial Number</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Manufacturer"
                    value={inputData.controllerDetails.serialNumber}
                    onChange={handleOnChange("controllerDetails-serialNumber")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Controller Type</Form.Label>
                  <div>
                    <input
                      type="radio"
                      name="property_typesi_id"
                      className={styles.customRadio}
                      id="custom-radiosi-1"
                      checked={inputData.controllerDetails.controllerType === "MPPT"}
                      onChange={handleOnChange("controllerDetails-controllerType")}
                      value="MPPT"
                    />
                    <label htmlFor="custom-radiosi-1" style={{ fontSize: "1rem" }}>
                      MPPT
                    </label>
                    <input
                      type="radio"
                      name="property_typesi_id"
                      className={styles.customRadio}
                      id="custom-radiosi-2"
                      checked={inputData.controllerDetails.controllerType === "PWM"}
                      onChange={handleOnChange("controllerDetails-controllerType")}
                      value="PWM"
                    />
                    <label htmlFor="custom-radiosi-2" style={{ fontSize: "1rem" }}>
                      PWM
                    </label>
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Controller Capacity (A/Watt)</Form.Label>
                  <div className="d-flex">
                    <div className="w-50">
                      <Form.Control
                        required
                        className="form-design"
                        type="number"
                        placeholder="Enter the controller capacity"
                        value={inputData.controllerDetails.controllerCapacity}
                        onChange={handleOnChange("controllerDetails-controllerCapacity")}
                      />
                    </div>
                    <div className="w-50 px-3 inline-radio">
                      <Form.Check
                        inline
                        label="A"
                        name="controllerCapacity"
                        type="radio"
                        id={`inline-controllerCapacity-1`}
                        checked={inputData.controllerDetails.controllerCapacityUnit === "A"}
                        onChange={handleOnChange("controllerDetails-controllerCapacityUnit")}
                        value="A"
                      />
                      <Form.Check
                        inline
                        label="Watt"
                        name="controllerCapacity"
                        type="radio"
                        id={`inline-controllerCapacity-2`}
                        checked={inputData.controllerDetails.controllerCapacityUnit === "Watt"}
                        onChange={handleOnChange("controllerDetails-controllerCapacityUnit")}
                        value="Watt"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <div className="form-btn-group">
              <Button variant="primary" type="submit">
                Save & Continue
              </Button>
              &ensp;
              <Button
                variant="outline-primary"
                onClick={() => history.push(`/project-status/${projectId}/installation`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>

        {submitting && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default TechinicalSpecification2;
