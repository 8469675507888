import React from "react";
import { Button } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";
// History
import history from "../../../history";

const GoBack: React.FC = () => {
  const onClick = () => {
    history.go(-1);
  };

  return (
    <div className="go-back">
      <Button variant="outline-primary" onClick={onClick}>
        <ChevronLeft />
        Go Back
      </Button>
    </div>
  );
};

export default GoBack;
