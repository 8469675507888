import React, { ChangeEvent, useState, useEffect } from "react";
import { Row, Col, Form, Button, ProgressBar, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// Storage
import {
  Province,
  getDistrictByProvince,
  getMunicipalitiesByDistrict,
} from "../../../../storage/geo-nepal";
// History
import history from "../../../../history";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../../components/shared";
// Helpers
import { getFarmerById, updateProject } from "../../../../helpers/api";
// Utils
import { HIGHWAYS } from "../../../../utils/enums";
// Types
import { ProjectDataType } from "../../../ts/types";
// Data
import { InitialProjectData } from "../InitialProjectData";
//helper
import { FISCAL_YEARS } from "../../../../helpers/fiscalYear";

const GeneralInformation: React.FC = () => {
  const [inputData, setInputData] = useState<ProjectDataType>(InitialProjectData);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    getFarmerById(projectId).then((res: any) => {
      if (res.status) {
        setInputData(res.data);
      }
    });
  }, [projectId]);

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [keyof ProjectDataType, any];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      if (levelTwoKey === "land0" || levelTwoKey === "land1") {
        const landValue = prevInputData?.[levelOneKey].land.split("-");
        const landKey = levelTwoKey === "land0" ? 0 : 1;
        landValue[landKey] = value;
        prevInputData[levelOneKey].land = landValue.join("-");
      } else {
        prevInputData[levelOneKey][levelTwoKey] = value;
      }
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    updateProject(inputData)
      .then((res: any) => {
        if (res.status) {
          toast.success("Project updated successfully");
          history.push(`/edit-project/${projectId}/technical-specification-1`);
        } else {
          toast.error("Error while submitting the request.");
        }
      })
      .finally(() => setSubmitting(false));
  };

  const getFilteredDistricts = () => {
    const region = inputData.address.region;
    if (!!region) {
      return getDistrictByProvince(region);
    }
    return [];
  };

  const getFilteredMunicipalities = () => {
    const district = inputData.address.district;
    if (!!district) {
      return getMunicipalitiesByDistrict(district);
    }

    return [];
  };

  const getIrrigationLandArea = (landType: string) => {
    if (landType === inputData.irrigationLandDetails.landType) {
      return inputData.irrigationLandDetails.land.split("-");
    } else {
      return ["", ""];
    }
  };

  return (
    <div>
      <Container className="form_container">
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Project Details" />
            </div>
          </div>

          <Divider type="light" size="medium" />
        </div>

        <div className="register-project m-l-5 my-5 form_cover">
          <Form onSubmit={handleOnSubmit}>
            <div className="progress-container mb-5">
              <h4 className="mb-3 mt-3">General Information</h4>
              <Row>
                <Col>
                  <ProgressBar now={100} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={0} className="custom-progress-bar" />
                </Col>
                <Col>
                  <ProgressBar now={0} className="custom-progress-bar" />
                </Col>
              </Row>
            </div>

            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={6} lg={4}>
                    <Form.Group className="mb-4">
                      <Form.Label>List No.</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="List No."
                        value={inputData.listNo}
                        onChange={handleOnChange("listNo")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} xl={4}>
                    <Form.Group className="mb-4">
                      <Form.Label>Fiscal Year</Form.Label>
                      <Form.Select
                        className="select-toggle"
                        value={inputData.fiscalYear}
                        onChange={handleOnChange("fiscalYear")}
                      >
                        <option>Fiscal Year</option>
                        {FISCAL_YEARS.map(({ title, value }) => (
                          <option value={title} key={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} lg={4}>
                    <Form.Group className="mb-4">
                      <Form.Label>Lot No.</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Lot No."
                        value={inputData.lot}
                        onChange={handleOnChange("lot")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Company Name"
                    value={inputData.companyName}
                    onChange={handleOnChange("companyName")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Date Installed</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="DD/MM/YY"
                    value={inputData.createdAt.split("T")[0]}
                    onChange={handleOnChange("createdAt")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Enter name of the beneficiary"
                    value={inputData.beneficiaryName}
                    onChange={handleOnChange("beneficiaryName")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Citizenship No.</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Enter citizenship no."
                    value={inputData.citizenshipNo}
                    onChange={handleOnChange("citizenshipNo")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Contact No. 1</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="number"
                    placeholder="Enter the primary contact number"
                    value={inputData.phoneNumber}
                    onChange={handleOnChange("phoneNumber")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Contact No. 2</Form.Label>
                  <Form.Control
                    className="form-design"
                    type="number"
                    placeholder="Enter the secondary contact number"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Province</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.address.region}
                    onChange={handleOnChange("address-region")}
                  >
                    <option>Select the province</option>
                    {Province.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>District</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.address.district}
                    onChange={handleOnChange("address-district")}
                  >
                    <option>Select district</option>
                    {getFilteredDistricts()?.map(({ name, id }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={8} lg={8}>
                    <Form.Group className="mb-4">
                      <Form.Label>Municipality</Form.Label>
                      <Form.Select
                        className="select-toggle"
                        value={inputData.address.municipality}
                        onChange={handleOnChange("address-municipality")}
                      >
                        <option>Select municipality</option>
                        {getFilteredMunicipalities()?.map(({ name, id }) => (
                          <option value={name} key={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={4} lg={4}>
                    <Form.Group className="mb-4">
                      <Form.Label>Ward </Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="text"
                        placeholder="Ward No."
                        value={inputData.address.ward}
                        onChange={handleOnChange("address-ward")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Tole</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Enter the tole"
                    value={inputData.address.tole}
                    onChange={handleOnChange("address-tole")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Nearest Highway</Form.Label>
                  <Form.Select
                    className="select-toggle"
                    value={inputData.address.nearestHighway}
                    onChange={handleOnChange("address-nearestHighway")}
                  >
                    <option>Select Highway</option>
                    {HIGHWAYS.map(({ title, value }) => (
                      <option value={title} key={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Distance from nearest highway</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Enter distance from nearest highway"
                    value={inputData.address.distanceFromNearestHighway}
                    onChange={handleOnChange("address-distanceFromNearestHighway")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Recommendwd By</Form.Label>
                  <div className="form-inline">
                    <Form.Select
                      className="select-toggle"
                      value={inputData.recommendedBy}
                      onChange={handleOnChange("recommendedBy")}
                    >
                      <option value="Municipality">Municipality</option>
                      <option value="Ward">Ward</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Total Cost</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Total Cost"
                        value={inputData.totalCost}
                        onChange={handleOnChange("totalCost")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Total Subsidy</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Total Subsidy"
                        value={inputData.totalSubsidy}
                        onChange={handleOnChange("totalSubsidy")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={3}>
                <Form.Group className="mb-4">
                  <Form.Label>User Contribution</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="number"
                    placeholder="User Contribution"
                    value={inputData.userContribution}
                    onChange={handleOnChange("userContribution")}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={8}>
                <Row>
                  <Col xs={12} lg={12}>
                    <Form.Group className="mb-4">
                      <Form.Label>Other Contributions (If Any)</Form.Label>
                      <div className="d-flex mb-3">
                        <div style={{ width: "50%" }}>
                          <Form.Control
                            className="form-design"
                            type="text"
                            placeholder="Contribution 1"
                            value={inputData.otherContributions.contribution1}
                            onChange={handleOnChange("otherContributions-contribution1")}
                          />
                        </div>
                        <div className="w-20 px-3 pt-1 inline-radio">
                          <Form.Check
                            inline
                            label="Rural/Municipality"
                            name="otherContribution-contribution1Type"
                            type="radio"
                            id={`inline-landAreaa-1`}
                            checked={
                              inputData.otherContributions.contribution1Type === "municipality"
                            }
                            onChange={handleOnChange("otherContributions-contribution1Type")}
                            value="municipality"
                          />

                          <Form.Check
                            inline
                            label="Ward"
                            name="otherContribution-contribution1Type"
                            type="radio"
                            id={`inline-landAreaa-3`}
                            checked={inputData.otherContributions.contribution1Type === "ward"}
                            onChange={handleOnChange("otherContributions-contribution1Type")}
                            value="ward"
                          />
                        </div>
                      </div>

                      <div className="d-flex">
                        <div style={{ width: "50%" }}>
                          <Form.Control
                            className="form-design"
                            type="text"
                            placeholder="Contribution 2"
                            value={inputData.otherContributions.contribution2}
                            onChange={handleOnChange("otherContributions-contribution2")}
                          />
                        </div>
                        <div className="w-20 px-3 pt-1 inline-radio">
                          <Form.Check
                            inline
                            label="Rural/Municipality"
                            name="landAreas"
                            type="radio"
                            id={`inline-landAreas-1`}
                            checked={
                              inputData.otherContributions.contribution2Type === "municipality"
                            }
                            onChange={handleOnChange("otherContributions-contribution2Type")}
                            value="municipality"
                          />
                          <Form.Check
                            inline
                            label="Ward"
                            name="landAreas"
                            type="radio"
                            id={`inline-landAreas-2`}
                            checked={inputData.otherContributions.contribution2Type === "ward"}
                            onChange={handleOnChange("otherContributions-contribution2Type")}
                            value="ward"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12} lg={5}>
                <Form.Group className="mb-4">
                  <Form.Label>Water Source</Form.Label>
                  <Form.Control
                    className="form-design"
                    required
                    type="text"
                    placeholder="Enter the Water source"
                    value={inputData.waterSourceDetails.source}
                    onChange={handleOnChange("waterSourceDetails-source")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={7}>
                <Row>
                  <Col xs={12} md={12} xl={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Total Head(m)</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Total Head"
                        value={inputData.waterSourceDetails.head}
                        onChange={handleOnChange("waterSourceDetails-head")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} xl={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Water Discharge(L/Day)</Form.Label>
                      <Form.Control
                        className="form-design"
                        required
                        type="number"
                        placeholder="Water Discharge"
                        value={inputData.waterSourceDetails.discharge}
                        onChange={handleOnChange("waterSourceDetails-discharge")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12} lg={8}>
                <Form.Group className="mb-4">
                  <Form.Label>Irrigation Land Area</Form.Label>
                  <div className="d-flex">
                    <div className="w-50">
                      <div className="form-inline">
                        {inputData.irrigationLandDetails.landType === "Bigha" ? (
                          <>
                            <Form.Control
                              type="number"
                              placeholder="Bigha"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("Bigha")[0]}
                              onChange={handleOnChange("irrigationLandDetails-land0")}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Kattha"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("Bigha")[1]}
                              onChange={handleOnChange("irrigationLandDetails-land1")}
                            />
                          </>
                        ) : (
                          <>
                            <Form.Control
                              type="number"
                              placeholder="Ropani"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("Ropani")[0]}
                              onChange={handleOnChange("irrigationLandDetails-land0")}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Anna"
                              className="w-50 form-design"
                              value={getIrrigationLandArea("Ropani")[1]}
                              onChange={handleOnChange("irrigationLandDetails-land1")}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="w-50 px-3 inline-radio">
                      <Form.Check
                        inline
                        label="Bigha"
                        name="landArea"
                        type="radio"
                        id={`inline-landArea-1`}
                        checked={inputData.irrigationLandDetails.landType === "Bigha"}
                        onChange={handleOnChange("irrigationLandDetails-landType")}
                        value="Bigha"
                      />
                      <Form.Check
                        inline
                        label="Ropani"
                        name="landArea"
                        type="radio"
                        id={`inline-landArea-2`}
                        checked={inputData.irrigationLandDetails.landType === "Ropani"}
                        onChange={handleOnChange("irrigationLandDetails-landType")}
                        value="Ropani"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <div className="form-btn-group">
              <Button variant="primary" type="submit">
                Save & Continue
              </Button>
              &ensp;
              <Button
                variant="outline-primary"
                onClick={() => history.push(`/project-status/${projectId}/installation`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>

        {submitting && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default GeneralInformation;
