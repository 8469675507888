import React, { ChangeEvent, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// History
import history from "../../../history";
// Helpers
import { createMeter } from "../../../helpers/api";
// Components
import { GoBack, PageTitle, Divider, LoadingOverlay } from "../../components/shared";
// Types
import { MeterCreateType, IrrigationLandDetailsType, AddressType } from "../../ts/types";

const initialInputData: MeterCreateType = {
  projectId: "",
  phoneNumber: "",
  hardwareId: "",
  fimewareVersion: "",
  meterType: "",
  designHead: 1,
  levelSensorPosition: 0,
  frequency: 1,
  status: "Y",
  pumpType: "AC",
  currentThreshold: 0,
  voltageThreshold: 0,
  systemEfficiency: 0,
  pumpEfficiency: 0,
  flowSensor: "Y",
  SWPS: "Y",
};

const CreateMeter: React.FC = () => {
  const [inputData, setInputData] = useState<MeterCreateType>(initialInputData);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();

  const handleOnChange = (inputKey: string) => (e: ChangeEvent<any>) => {
    let [levelOneKey, levelTwoKey] = inputKey.split("-") as [
      keyof MeterCreateType,
      keyof AddressType | keyof IrrigationLandDetailsType
    ];
    const value = e.target.value;

    const prevInputData = { ...inputData } as any;
    if (levelTwoKey) {
      prevInputData[levelOneKey][levelTwoKey] = value;
    } else {
      prevInputData[levelOneKey] = value;
    }

    setInputData(prevInputData);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = { ...inputData, projectId };
    createMeter(formData)
      .then((res: any) => {
        if (res.status) {
          toast.success("Meter created successfully");
          history.push(`/meter-details/${projectId}`);
        } else {
          toast.error("Error while submitting the request.");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <div className="page-header">
        <div className="d-flex p-3">
          <GoBack />
          <div className="flex-grow-1 text-center">
            <PageTitle title="Create Meter" />
          </div>
        </div>
        <Divider type="dark" />
      </div>

      <div className="register-project m-5">
        <Form onSubmit={handleOnSubmit}>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the meter SIM Card No."
                  value={inputData.phoneNumber}
                  onChange={handleOnChange("phoneNumber")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Hardware ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Hardware ID"
                  value={inputData.hardwareId}
                  onChange={handleOnChange("hardwareId")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Firmware Version</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Firmware Version"
                  value={inputData.fimewareVersion}
                  onChange={handleOnChange("fimewareVersion")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Meter Type</Form.Label>
                <Form.Select value={inputData.meterType} onChange={handleOnChange("meterType")}>
                  <option>Select the meter type</option>
                  <option value="Agriculture Meter">Agriculture Meter</option>
                  <option value="Fish Meter">Fish Meter</option>
                  <option value="Smart Meter">Smart Meter</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Design Head</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Design Head"
                  value={inputData.designHead}
                  min="1"
                  onChange={handleOnChange("designHead")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Level Sensor Position</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Level Sensor Position"
                  value={inputData.levelSensorPosition}
                  onChange={handleOnChange("levelSensorPosition")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Frequency</Form.Label>
                <Form.Select value={inputData.frequency} onChange={handleOnChange("frequency")}>
                  <option>Select frequency</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Pump Type</Form.Label>
                <Form.Select value={inputData.pumpType} onChange={handleOnChange("pumpType")}>
                  <option value="AC">AC</option>
                  <option value="DC">DC</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Current Threshold</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Current Threshold"
                  min="0"
                  step="0.01"
                  value={inputData.currentThreshold}
                  onChange={handleOnChange("currentThreshold")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Voltage Threshold</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Voltage Threshold"
                  min="0"
                  step="0.01"
                  value={inputData.voltageThreshold}
                  onChange={handleOnChange("voltageThreshold")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>System efficiency</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="System efficiency"
                  min="0"
                  max="1"
                  step="0.01"
                  value={inputData.systemEfficiency}
                  onChange={handleOnChange("systemEfficiency")}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Pump efficiency</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Pump efficiency"
                  min="0"
                  max="1"
                  step="0.01"
                  value={inputData.pumpEfficiency}
                  onChange={handleOnChange("pumpEfficiency")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>Flow Sensor</Form.Label>
                <Form.Select value={inputData.flowSensor} onChange={handleOnChange("flowSensor")}>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4">
                <Form.Label>SWPS Status</Form.Label>
                <Form.Select value={inputData.SWPS} onChange={handleOnChange("SWPS")}>
                  <option value="Y">Meter with SWPS</option>
                  <option value="N">Meter without SWPS</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </div>

      {submitting && <LoadingOverlay />}
    </div>
  );
};

export default CreateMeter;
