import React from "react";
// import { FileEarmarkRichtext } from "react-bootstrap-icons";
import styles from "./FilePicker.module.scss";
import { FaFile } from "react-icons/fa";

interface Props {
  placeholder?: string;
  onFileSelect: (file: File) => void;
  id: string;
}

const FilePickerTwo: React.FC<Props> = ({ id, placeholder, onFileSelect }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onFileSelect(e.target.files?.[0]);
    }
  };

  return (
    <div className={styles.filePickerTwo}>
      <label className={styles.filePickerLabelTwo} htmlFor={`custom-file-upload-${id}`}>
        <FaFile size={30} color="#ADADAD" style={{ marginRight: "10px" }} />
        {placeholder || "Upload File"}
      </label>
      <input type="file" className="d-none" id={`custom-file-upload-${id}`} onChange={onChange} />
    </div>
  );
};

export default FilePickerTwo;
