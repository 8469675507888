// Interfaces
import { RouteInterface } from "./ts/interfaces";

// Views
import Dashboard from "./views/dashboard/Dashboard";
import CreateMeter from "./views/meter/CreateMeter";
import EditMeter from "./views/meter/EditMeter";
import RegisterProject from "./views/projects/RegisterProject";
import Registration from "./views/projectStatus/Registration";
import Initiation from "./views/projectStatus/Initiation";
import Installation from "./views/projectStatus/Installation";
import Operational from "./views/projectStatus/Operational";
import GeneralInformation from "./views/projects/editProject/GeneralInformation";
import TechinicalSpecification1 from "./views/projects/editProject/TechnicalSpecification1";
import Structure from "./views/projects/editProject/Structure";
import TechinicalSpecification2 from "./views/projects/editProject/TechnicalSpecification2";
import TechinicalSpecification3 from "./views/projects/editProject/TechinicalSpecification3";
import TechinicalSpecification4 from "./views/projects/editProject/TechnicalSpecification4";
import ProjectDetails from "./views/projectStatus/ProjectDetails";
import MeterDetails from "./views/meter/MeterDetails";
import EditInfo from "./views/projects/EditInfo";
import CreateOrganisation from "./views/organisation/RegisterOrganisation";
import RegisterSubOrganisation from "./views/subOrganization/RegisterSubOrganization";
import AddBeneficary from "./views/beneficiary/AddBeneficary";
import ViewBeneficiary from "./views/beneficiary/ViewBeneficiary";
import Organizations from "./views/organisation/Organizations";
import EditOrganisation from "./views/organisation/EditOrganisation";
import EditBeneficary from "./views/beneficiary/EditBeneficiary";
import ConfigureMeter from "./views/meter/ConfigureMeter";

export const RootRoutes: RouteInterface[] = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/register-project",
    component: RegisterProject,
  },
  {
    path: "/edit-project/:projectId/general-information",
    component: GeneralInformation,
  },
  {
    path: "/edit-project/:projectId/technical-specification-1",
    component: TechinicalSpecification1,
  },
  {
    path: "/edit-project/:projectId/technical-specification-2",
    component: TechinicalSpecification2,
  },
  {
    path: "/edit-project/:projectId/technical-specification-3",
    component: TechinicalSpecification3,
  },
  {
    path: "/edit-project/:projectId/technical-specification-4",
    component: TechinicalSpecification4,
  },
  {
    path: "/edit-project/:projectId/structure",
    component: Structure,
  },
  {
    path: "/create-meter/:projectId",
    component: CreateMeter,
  },
  {
    path: "/edit-meter/:projectId",
    component: EditMeter,
  },
  {
    path: "/configure-meter/:projectId",
    component: ConfigureMeter,
  },
  {
    path: "/meter-details/:projectId",
    component: MeterDetails,
  },
  {
    path: "/project-status/:projectId/registration",
    component: Registration,
  },
  {
    path: "/project-status/:projectId/editInfo",
    component: EditInfo,
  },
  {
    path: "/project-status/:projectId/initiation",
    component: Initiation,
  },
  {
    path: "/project-status/:projectId/installation",
    component: Installation,
  },
  {
    path: "/project-status/:projectId/projectDetails",
    component: ProjectDetails,
  },
  { path: "/project-status/:projectId/operational", component: Operational },
  {
    path: "/register-organisation",
    component: CreateOrganisation,
  },
  {
    path: "/organisation/:organizationId/editInfo",
    component: EditOrganisation,
  },
  {
    path: "/organization",
    component: Organizations,
  },
  {
    path: "/register-sub-organisation",
    component: RegisterSubOrganisation,
  },
  {
    path: "/add-beneficiary/:projectId",
    component: AddBeneficary,
  },
  {
    path: "/edit-beneficiary/:beneficiaryId",
    component: EditBeneficary,
  },
  {
    path: "/project/beneficiaries/:projectId",
    component: ViewBeneficiary,
  },
];
