import { set as setDate, add } from "date-fns";
import React from "react";
import { BsFillCircleFill } from "react-icons/bs";
import {
  BarChart,
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  CustomizedProps,
} from "recharts";
import { formatDate } from "../../../../helpers/dateTime";
import { Colors } from "../../../constants/colors";
import { ChartTypes } from "../../../ts/enums";
import { MeterDailyType } from "../../../ts/types";

type PlotData = { name: string; value: number };
type ChartProps = {
  filterOptions: MeterDailyType;
  graphData: Array<PlotData>;
  labels?: {
    xAxisLabel?: string;
    yAxisLabel?: string;
  };
  type: ChartTypes;
  referenceLine?: {
    name: string;
    value: number;
  } | null;
  tooltipLabelType: TooltipLabelTypes;
};

export enum TooltipLabelTypes {
  STATIC = "static",
  DATE = "date",
}

type CustomTooltipProps = {
  tooltipLabelType: TooltipLabelTypes;
};

const CustomTooltip = ({
  active,
  payload,
  referenceLine,
  tooltipLabelType,
}: CustomTooltipProps | any) => {
  //payload is the object contaiing graphData, processed by recharts internally
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label" style={{ textAlign: "center", color: "grey" }}>{`${
          tooltipLabelType === TooltipLabelTypes.DATE
            ? formatDate(payload[0].payload.name)
            : payload[0].payload.name
        } `}</p>
        <hr />
        <p className="desc">
          <BsFillCircleFill
            size="0.9rem"
            style={{
              position: "relative",
              bottom: "2px",
              marginRight: "1rem",
              color: Colors.BLUE,
            }}
          />
          Value : {` ${payload[0].value.toFixed(2)}`}
        </p>
        {referenceLine && (
          <p className="desc">
            <BsFillCircleFill
              size="0.9rem"
              style={{
                position: "relative",
                bottom: "2px",
                marginRight: "1rem",
                color: Colors.LIGHT_GREEN,
              }}
            />
            {`${referenceLine.name}`}: {` ${referenceLine.value}`}
          </p>
        )}
      </div>
    );
  }

  return null;
};

const Chart = ({ filterOptions, graphData, labels, type, referenceLine, ...rest }: ChartProps) => {
  //tick generator
  const startOfDay = React.useMemo(() => {
    return setDate(new Date(filterOptions.from), { hours: 0, minutes: 0 }).getTime();
  }, [filterOptions.from]);
  const endOfDay = React.useMemo(() => {
    return setDate(new Date(filterOptions.from), { hours: 23, minutes: 59, seconds: 59 }).getTime();
  }, [filterOptions.from]);

  const dayTicks = React.useMemo(() => {
    const todayTicks = [];
    let now = startOfDay;
    while (now <= endOfDay) {
      todayTicks.push(now);
      now = add(now, { hours: 1 }).getTime();
    }
    return todayTicks;
  }, [startOfDay, endOfDay]);

  const yTicks = () => {
    const normalize = (value: number) => {
      return value + 4 - (value % 4);
    };
    const maxPlotValue = Math.ceil(Math.max(...graphData.map((each) => each.value)));
    const referenceValue = Math.ceil(referenceLine?.value || 0);
    const maxPlotValueNormalized = normalize(maxPlotValue);
    const referenceValueNormalized = normalize(referenceValue);
    const chosenValue = Math.max(maxPlotValueNormalized, referenceValueNormalized);

    let plotValues = [0];
    for (let i = 1; i <= 4; i++) {
      plotValues.push((chosenValue / 4) * i);
    }
    return plotValues;
  };

  const chartLabels = (
    <>
      <YAxis
        label={{
          value: labels?.yAxisLabel,
          angle: -90,
          position: "insideLeft",
          offset: "0",

          style: { color: "#fff", marginLeft: "500px" },
        }}
        scale="auto"
        dataKey="value"
        ticks={yTicks()}
        allowDataOverflow={true}
        axisLine={false}
      />
    </>
  );

  const reference = (
    <>
      {referenceLine && (
        <ReferenceLine
          y={referenceLine.value}
          // label={referenceLine.name}
          stroke={Colors.LIGHT_GREEN}
          strokeDasharray="10 4"
          strokeWidth={4}
        />
      )}
    </>
  );
  return (
    <>
      {type == ChartTypes.LINEAR_AREA && (
        <div className="chart">
          <ResponsiveContainer width="90%" height={400}>
            <AreaChart
              width={900}
              height={400}
              data={graphData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={Colors.BLUE} stopOpacity={0.7} />
                  <stop offset="95%" stopColor={Colors.LIGHT_BLUE} stopOpacity={0.05} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                interval="preserveEnd"
                tickFormatter={formatDate}
                tickCount={100}
                ticks={dayTicks}
                scale="time"
                type="number"
                domain={[startOfDay, endOfDay]}
                label={{
                  value: labels?.xAxisLabel,
                  position: "bottom",
                  type: "centered",
                  style: { color: "#c1c1c1" },
                }}
              />

              <Area
                type="monotone"
                dataKey="value"
                stroke={Colors.BLUE}
                fillOpacity={1}
                fill="url(#colorUv)"
                strokeWidth={3}
              />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <Label value="Time" offset={8} position="bottom" style={{ color: "#c1c1c1" }} />

              <Tooltip
                labelFormatter={formatDate}
                content={
                  <CustomTooltip
                    customLabel={filterOptions.filter}
                    referenceLine={referenceLine}
                    tooltipLabelType={TooltipLabelTypes.DATE}
                  />
                }
              />
              {chartLabels}
              {reference}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}

      {type == ChartTypes.BAR && (
        <ResponsiveContainer width="90%" height={400}>
          <BarChart
            width={900}
            height={400}
            data={graphData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4c81e09c" stopOpacity={1} />
                <stop offset="95%" stopColor="#4c81e09c" stopOpacity={0.5} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              label={{
                value: labels?.xAxisLabel,
                position: "bottom",
                type: "centered",
                style: { color: "#c1c1c1" },
              }}
            />
            {chartLabels}
            {reference}
            <Bar dataKey="value" fill="url(#colorUv)" order={2} />
            <Tooltip
              active={false}
              viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
              content={
                <CustomTooltip
                  referenceLine={referenceLine}
                  tooltipLabelType={TooltipLabelTypes.STATIC}
                />
              }
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default Chart;
